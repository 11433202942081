import React, { Component } from 'react';
import { Route, HashRouter as Router, Redirect, Switch } from 'react-router-dom';
import Dashboard from '../Pages/User/Dashboard';
import Login from '../Pages/User/Login';
import Signup from '../Pages/User/Signup';
import Profile from '../Pages/User/Profile';
import Header from '../Include/Header';
import Sidebar from '../Include/Sidebar';
import Store from '../Pages/User/store';
import MySkill from '../Pages/User/MySkills';

export default class Routes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ischeckAuth: false,
			loading: false,
			isLogout: false
		}
		this.store = Store;
	}

	componentDidMount = async () => {
		const token = await localStorage.getItem('token');
		if (!token) {
			console.log("Logout")
			// Auth.Logout();
			await this.setState({ isLogout: false, })
		} else {
			await this.setState({ loading: false });
			console.log("Is Token")
		}
	}

	render() {
		if (this.state.loading && this.state.isLogout) {
			return (<Redirect to='/login' />)
		} else if (this.state.loading) {
			return (<div>Loading...</div>);
		}
		else {
			return (
				<Router forceRefresh={true}>
					<div id="wrapper" className="is-verticle">
						<Header />
						<div class="main_content">
							<Switch>
								<Route path="/" exact name="home" component={Dashboard}></Route>
								<Route path='/Profile' name="Profile" component={Profile}></Route>
								<Route path='/MySkill' name="MySkill" component={MySkill}></Route>
							</Switch>
						</div>
						<Sidebar />
					</div>
				</Router>
			);
		}
	}
}