import React, { Component } from 'react';
import { Route, HashRouter as Router, Switch, Redirect, } from 'react-router-dom';
import Dashboard from '../Pages/Admin/Dashboard';
import Header from '../Include/Admin/Header'
import Sidebar from '../Include/Admin/Sidebar'

export default class adminRoutes extends Component {
    render() {
        return (
            <Router forceRefresh={true}>
                <div id="wrapper" class="is-verticle admin-panel">
                    <Header />
                    <div class="main_content">
                        <Switch>
                            <Route path="/" exact name="home" component={Dashboard}></Route>
                        </Switch>
                    </div>
                    <Sidebar />
                </div>
            </Router>
        )
    }

}