import React, { Component } from 'react';
import { Route, HashRouter as Router, Redirect, Switch } from 'react-router-dom';
import Home from '../Pages/Home';
// import Login from '../Pages/Login';
// import Signup from '../Pages/Signup';
// import Profile from '../Pages/Profile';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Store from '../Pages/User/store';

export default class Routes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ischeckAuth: false,
			loading: false,
			isLogout: false
		}
		this.store = Store;
	}

	componentDidMount = async () => {
		const token = await localStorage.getItem('token');
		if (!token) {
			console.log("Logout")
			// Auth.Logout();
			await this.setState({ isLogout: false, })
		} else {
			await this.setState({ loading: false });
			console.log("Is Token")
		}
	}

	render() {
		if (this.state.loading && this.state.isLogout) {
			return (<Redirect to='/login' />)
		} else if (this.state.loading) {
			return (<div>Loading...</div>);
		}
		else {
			return (
				<Router forceRefresh={true}>
					<div id="wrapper" class="flex flex-col justify-between h-screen">
						<Header />
						<div>
							<Switch>
								<Route path="/" exact name="home" component={Home}></Route>
								{/* <Route path='/Profile' name="Profile" component={Profile}></Route> */}
							</Switch>
						</div>
						<Footer />
					</div>
				</Router>
			);
		}
	}
}