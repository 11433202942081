import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class Header extends Component {

    render() {

        return (

            // < !--header-- >
            <div className="bg-white py-4 shadow dark:bg-gray-800">
                <div className="max-w-6xl mx-auto">


                    <div className="flex items-center lg:justify-between justify-around">

                        <Link to={'/'}>
                            {/* <img src="../assets/images/logo.png" alt="" className="w-32" /> */}
                            <span style={{fontSize:'x-large',color:"#361A54",fontWeight:'600'}}>Virtual Skills Platform</span>
                        </Link>

                        <div className="capitalize flex font-semibold hidden lg:block my-2 space-x-3 text-center text-sm">
                            <a href="/login" className="py-3 px-4">Login</a>
                            <a href="/sign-up" className="bg-purple-500 purple-500 px-6 py-3 rounded-md shadow text-white">Register</a>
                        </div>

                    </div>


                </div>
            </div>

        );
    }
}

export default Header;