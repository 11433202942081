import React, { Component } from 'react';

class Sidebar extends Component {

    render() {

        return (

            // < !--sidebar -- >
            <div className="sidebar">
                <div className="sidebar_inner" data-simplebar>
                    <ul>
                        <li className="active"><a href="dashboard.html">
                            {/* <ion-icon name="home-outline" className="side-icon md hydrated"></ion-icon> */}
                            <ion-icon name="home-outline" class="side-icon md hydrated" role="img" aria-label="home outline"></ion-icon>
                            <span> Dashboard </span>
                        </a>
                        </li>
                        <li><a href="#">
                            {/* <ion-icon name="play-circle-outline" className="side-icon"></ion-icon> */}
                            <ion-icon name="play-circle-outline" class="side-icon md hydrated" role="img" aria-label="play circle outline"></ion-icon>
                            <span> Courses</span>
                        </a>
                            <ul>
                                <li><a href="courses.html"> Courses List </a></li>
                                <li><a href="courses-card.html"> Courses Grid </a></li>
                                <li><a href="create-new-course.html"> Add new course </a></li>
                            </ul>
                        </li>
                        <li><a href="books.html">
                            <ion-icon name="book-outline" class="side-icon md hydrated" role="img" aria-label="book-outline"></ion-icon>
                            <span> Books</span>
                        </a>
                        </li>
                        <li><a href="messages.html">
                            <ion-icon name="chatbox-ellipses-outline" class="side-icon md hydrated" role="img" aria-label="chatbox-ellipses-outline"></ion-icon>
                            <span> Message</span>
                        </a>
                        </li>
                        <li><a href="#">
                            <ion-icon name="people-circle-outline" class="side-icon md hydrated" role="img" aria-label="people-circle-outline">
                            </ion-icon>
                            <span> Instructors</span>
                        </a>
                            <ul>
                                <li><a href="instructors.html"> List View </a></li>
                                <li><a href="instructors-card.html"> Grid View </a></li>
                            </ul>
                        </li>
                        <li><a href="#">
                            <ion-icon name="people-outline" class="side-icon md hydrated" role="img" aria-label="people-outline"></ion-icon>
                            <span> Students</span>
                        </a>
                            <ul>
                                <li><a href="students.html"> List View </a></li>
                                <li><a href="student-card.html"> Grid View </a></li>
                            </ul>
                        </li>
                        <li><a href="categories.html">
                            <ion-icon name="albums-outline" class="side-icon md hydrated" role="img" aria-label="albums-outline"></ion-icon>
                            <span> Categories</span>
                        </a>
                        </li>
                        <li><a href="setting.html">
                            <ion-icon name="settings-outline" class="side-icon md hydrated" role="img" aria-label="settings-outline"></ion-icon>
                            <span> Setting</span>
                        </a>
                            <ul>
                                <li><a href="setting.html"> Website Information </a></li>
                                <li><a href="setting.html"> General Configuration </a></li>
                                <li><a href="setting.html"> Social Login </a></li>
                                <li><a href="setting.html"> Setup payment </a></li>
                            </ul>
                        </li>
                    </ul>
                    <ul className="side_links" data-sub-title="">
                        <li><a href="personal-profile.html">
                            <ion-icon name="person-outline" class="side-icon md hydrated" role="img" aria-label="person-outline"></ion-icon>
                            <span> Manage profile </span>
                        </a>
                        </li>
                        <li><a href="form-login.html">
                            <ion-icon name="log-out-outline" class="side-icon md hydrated" role="img" aria-label="log-out-outline"></ion-icon>
                            <span> logout </span>
                        </a>
                        </li>
                    </ul>
                </div>
                <div className="side_overly" uk-toggle="target: #wrapper ; cls: is-collapse is-active"></div>
            </div>
        );
    }
}

export default Sidebar;