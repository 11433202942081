import React, { Component } from 'react';

class OnlineHelp extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            user: null,
            SearchText: "",
            redirect: false,
            model: false,
            DegreeList: []
        }
        // console.log(QuestionAnswer.questionAnswer)
    }

    componentDidMount() {
        this.getUser();
    }

    getUser = async () => {

        let user = localStorage.getItem('user');
        user = JSON.parse(user);
        if (user) {
            this.setState({ loading: false, user: user });
            console.log("found user: ", user)
        }
        else {
            this.setState({ loading: false, user: null, redirect: true })
        }
    }

    render() {
        let { user } = this.state

        if (this.state.loading) {
            return (
                <>
                    loading...
                </>
            )
        }
        else {
            return (
                <>
                    <div className="container">
                        <div className="breadcrumb-area py-0 md:mb-10 mb-6 justify-between sm:flex flex-column">
                            <h3 className="md:text-2xl text-lg font-semibold mb-2"> Online Help </h3>
                            <ul class="breadcrumb flex flex-row text-md">
                                <li className="text-md"><a href="demo#/dashboard">Dashboard</a></li>
                                <li className="text-md"><a href="demo#/my-profile">User Profile</a></li>
                                <li className="text-md active">Online Help</li>
                            </ul>
                        </div>

                        <div className="grid lg:grid-cols-3 mt-12 gap-8">
                            <div className="bg-white rounded-md lg:shadow-md shadow col-span-3">
                                <div className="grid gap-3 lg:p-6 p-4">

                                    <div class="message-content">

                                        {/* <div class="messages-headline">
                                            <h4> Stella Johnson </h4>
                                            <a href="#" class="message-action text-red-500"><i class="icon-feather-trash-2"></i> <span class="md:inline hidden"> Delete Conversation</span> </a>
                                        </div> */}

                                        <div class="message-content-scrolbar" data-simplebar style={{ maxHeight: '500px' }}>

                                            {/* <!-- Message Content Inner --> */}
                                            <div class="message-content-inner" >

                                                {/* <!-- Time Sign --> */}
                                                <div class="message-time-sign">
                                                    <span>12 Oct, 2021</span>
                                                </div>

                                                <div class="message-bubble">
                                                    <div class="message-bubble-inner">
                                                        <div class="message-avatar"><img src="../assets/images/avatars/avatar-2.jpg" alt="" /></div>
                                                        <div class="message-text"><p>How may i help you?</p></div>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                </div>

                                                {/* <div class="message-bubble">
                                                    <div class="message-bubble-inner">
                                                        <div class="message-avatar"><img src="../assets/images/avatars/avatar-2.jpg" alt="" /></div>
                                                        <div class="message-text"><p>Laoreet.  dolore magna imperdiet quod mazim placerat facer possim. 👍</p></div>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                </div> */}

                                                <div class="message-bubble me">
                                                    <div class="message-bubble-inner">
                                                        <div class="message-avatar"><img src="../assets/images/avatars/avatar-2.jpg" alt="" /></div>
                                                        <div class="message-text"><p>I have issue in finding my career path.</p></div>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                </div>

                                                

                                                <div class="message-bubble">
                                                    <div class="message-bubble-inner">
                                                        <div class="message-avatar"><img src="../assets/images/avatars/avatar-2.jpg" alt="" /></div>
                                                        <div class="message-text">
                                                            {/* <!-- Typing Indicator --> */}
                                                            <div class="typing-indicator">
                                                                <span></span>
                                                                <span></span>
                                                                <span></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                </div>
                                            </div>
                                            {/* <!-- Message Content Inner / End --> */}

                                            {/* <!-- Reply Area --> */}
                                            {/* <div class="message-reply"> */}

                                        </div>
                                        <div class="message-reply" style={{ bottom: '0px', width: "100%", paddingBottom: 0, backgroundColor: 'white' }}>
                                            <textarea cols="1" rows="1" placeholder="Your Message"></textarea>
                                            <button class="button ripple-effect">Send</button>
                                            {/* <textarea cols="1" rows="1" placeholder="Your Message">

                                                </textarea>
                                                <button class="button ripple-effect">Send</button> */}
                                            {/* </div> */}
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </>
            );
        }
    }
}

export default OnlineHelp;