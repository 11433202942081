import React, { Component } from 'react';

class CareerPath1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            model: false,
            one: {
                title: 'Wiki',
                content: "What Is a Full Stack Java Developer? A full stack Java developer is a web developer that uses the Java language in the development of the -entire technology stack of a web-based application.",
                subContent: "Here are Full Stack Developer skills that are non-negotiable!",
                details: [
                    "HTML/CSS. While HTML stands for Hypertext Markup Language, CSS stands for Cascading Style Sheets",
                    "JavaScript",
                    "Git and GitHub",
                    "Backend languages.",
                    "Web architecture.",
                    "HTTP and REST.",
                    "Database storage.",
                    "Basic design skills.",
                ],
                questions: "What are the skills required for Java full stack developer?",
                link: 'https://www.webenrol.com/skillsandlearningace/?page=detail&courseCode=AT1923A',
            }
        }
    }

    onCardDisplay(CardDetails) {
        return (
            <div className={"circle text-white p-2 text-center " + CardDetails.className} onClick={() => { CardDetails.ModelDetails?.title && this.setState({ model: true, selectCardDetails: CardDetails.ModelDetails }) }}>
                <div>{CardDetails.name}</div>
                {
                    CardDetails.startToEndYear &&
                    <div className="date-position text-gray-900">({CardDetails.startToEndYear})</div>
                }
                {
                    CardDetails.icon &&
                    <div className={"mark-position " + CardDetails.icon.className} title={CardDetails.icon.title}><ion-icon class="text-sm" name={CardDetails.icon.name}></ion-icon></div>
                }
            </div>
        )
    }

    render() {
        const { ChartData } = this.props;
        // console.log(ChartData)
        return (
            <div className="bg-white rounded-md lg:shadow-md shadow col-span-3" uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 50">
                <div className="grid lg:grid-cols-1 gap-5 rounded-sm py-8">
                    <div className="wrapper-horizontal">
                        <ul className="ul-first-child flex">
                            {
                                ChartData?.Step1 && ChartData?.Step1.name &&
                                <li>
                                    {ChartData?.Step1 && this.onCardDisplay(ChartData.Step1)}
                                </li>
                            }
                            {
                                ChartData?.Step2 && ChartData?.Step2.name &&
                                <li>
                                    {ChartData?.Step2 && this.onCardDisplay(ChartData.Step2)}
                                </li>
                            }
                            {
                                ChartData?.Step3 && ChartData?.Step3.name &&
                                <li>
                                    {ChartData?.Step3 && this.onCardDisplay(ChartData.Step3)}
                                </li>
                            }
                        </ul>

                        {
                            ChartData?.Step4 && ChartData?.Step4.name &&
                            <ul className="flex flex-row justify-end mt-16 third-child">
                                <li>
                                    {ChartData?.Step4 && this.onCardDisplay(ChartData.Step4)}
                                </li>
                            </ul>
                        }
                        {
                            ChartData?.Step5 && ChartData?.Step5.name &&
                            <ul className="flex flex-row justify-end mt-16">
                                <li>
                                    {ChartData?.Step5 && this.onCardDisplay(ChartData.Step5)}
                                </li>
                            </ul>
                        }
                    </div>
                </div>
                <div className="grid grid-cols-3 gap-4 py-5 pl-5">
                    <div className="col-span-2">
                        <div className="grid grid-cols-3 gap-4 py-1">
                            {/* <div className="col-span-1 row-span-1 text-center">ds</div> */}
                            <div className="col-span-2 row-span-2 flex flex-rows">
                                <div className="icon-selected rounded-full bg-green-900 text-white mr-2">
                                    <ion-icon class="text-sm w-full" name="checkmark-outline"></ion-icon>
                                </div>
                                Natural progression – coherent
                            </div>
                        </div>
                        <div className="grid grid-cols-3 gap-4 py-1">
                            {/* <div className="col-span-1 row-span-1 text-center">ds</div> */}
                            <div className="col-span-2 row-span-2 flex flex-rows">
                                <div className="icon-selected rounded-full bg-yellow-600 text-white mr-2">
                                    <ion-icon class="text-sm w-full" name="warning-sharp"></ion-icon>
                                </div>
                                Uncoherent progression - event
                            </div>
                        </div>
                        <div className="grid grid-cols-3 gap-4 py-1">
                            {/* <div className="col-span-1 row-span-1 text-center">ds</div> */}
                            <div className="col-span-2 row-span-2 flex flex-rows">
                                <div className="icon-selected rounded-full bg-red-700 text-white mr-2">
                                    <ion-icon class="text-sm w-full" name="heart-outline"></ion-icon>
                                </div>
                                Desired next step - validated
                            </div>
                        </div>
                        <div className="grid grid-cols-3 gap-4 py-1">
                            <div className="col-span-2 row-span-2 flex flex-rows">
                                <div className="icon-selected rounded-full bg-yellow-700 text-white mr-2">
                                    <ion-icon class="text-sm w-full" name="heart-dislike-sharp"></ion-icon>
                                </div>
                                Potential next step – not validated
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-sections" uk-modal='' className={`uk-modal ${this.state.model ? 'uk-open uk-scrollspy-inview uk-animation-fade' : ""}`} style={this.state.model ? { display: 'block', visibility: "inherit" } : {}}>
                    <div className="uk-modal-dialog">
                        <button className="uk-modal-close-default m-3" type="button" onClick={() => this.setState({ model: false, selectCardDetails: null })} uk-close=''></button>
                        <div className="uk-modal-header">
                            <h2 className="uk-modal-title">{this.state.selectCardDetails?.title}</h2>
                        </div>
                        <div className="uk-modal-body">
                            {
                                this.state.selectCardDetails?.image &&
                                <div className='row'>
                                    <div className="col-md-4">
                                        {/* <img src={this.state.selectCardDetails?.image} alt="provider" /> */}
                                        <img src={`assets/images/Partners/${this.state.selectCardDetails?.image}.png`} alt="provider" />
                                    </div>
                                </div>
                            }
                            <br />
                            {
                                this.state.selectCardDetails?.content &&
                                <div dangerouslySetInnerHTML={{ __html: this.state.selectCardDetails?.content }} />
                            }
                            <p className="pt-3">{this.state.selectCardDetails?.questions}</p>
                            <p className="py-3 font-semibold">{this.state.selectCardDetails?.subContent}</p>
                            {/* <br /> */}
                            {
                                this.state.selectCardDetails?.extraDetails?.map((details, index) => {
                                    return (
                                        <div>
                                            {
                                                Object.entries(details).map((data, positions) => {
                                                    return (
                                                        <div className="pb-3" key={positions}>
                                                            <p className="text-right text-gray-800 font-semibold leading-5">{data[0]}</p>
                                                            <p className="text-right text-gray-800 leading-5">{data[1]}</p>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                            {
                                this.state.selectCardDetails?.details &&
                                <div className="pl-4">
                                    <ul className="list-disc">
                                        {this.state.selectCardDetails?.details?.map((d, i) => <li key={i}><p className="">{d}</p></li>)}
                                    </ul>
                                </div>
                            }
                            {
                                this.state.selectCardDetails?.date &&
                                <p>Date: {this.state.selectCardDetails?.date}</p>
                            }
                        </div>
                        {
                            this.state.selectCardDetails?.link &&
                            <div className="uk-modal-footer text-right">
                                {/* <button className="button gray uk-modal-close" type="button" onClick={() => this.setState({ model: false })}>Cancel</button> */}
                                <a className="button" target="_blank" href={`${this.state.selectCardDetails.link}`} onClick={() => this.setState({ model: false, selectCardDetails: null })}>Interested</a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default CareerPath1;