import React, { Component } from 'react';
import Categories from '../Components/Categories/Categories'

class Home extends Component {
    render() {
        return (
            <>
                {/* <div class="lg:p-12 max-w-xl lg:my-0 my-12 mx-auto p-6 space-y-">

            </div> */}
                <div class="container -mt-7 lg:mt-5">
                    <div class="tube-card p-0 md:m-0 -mx-5" style={{ marginBottom: '6rem' }}>
                        <div class="embed-video rounded -mx-6 -mt-6 lg:m-0">
                            <iframe class="uk-responsive-width" src="https://www.youtube.com/embed/jfHVMZGeQcg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div class="relative -mt-3" uk-slider="finite: true">

                        <div class="uk-slider-container px-1 py-3" style={{ marginBottom: '5rem' }}>
                            <ul class="uk-slider-items uk-child-width-1-10@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-small uk-grid" style={{ justifyContent: 'center' }}>
                                <li>
                                    <div class="rounded-md overflow-hidden relative w-full h-20">
                                        <div class="absolute w-full h-3/4 -bottom-12 z-10">
                                        </div>
                                        <img src="/assets/images/Partners/bu.png" class="absolute w-full h-full object-contain" style={{ width: '100px' }} alt="" />
                                    </div>
                                </li>
                                <li>
                                    <div class="rounded-md overflow-hidden relative w-full h-20">
                                        <div class="absolute w-full h-3/4 -bottom-12 z-10">
                                        </div>
                                        <img src="/assets/images/Partners/uni.png" class="absolute w-full h-full object-contain" style={{ width: '100px' }} alt="" />
                                    </div>
                                </li>
                                <li>
                                    <div class="rounded-md overflow-hidden relative w-full h-20">
                                        <div class="absolute w-full h-3/4 -bottom-12 z-10">
                                        </div>
                                        <img src="/assets/images/Partners/Logo_Partners_2.png" class="absolute w-full h-full object-contain" style={{ width: '100px' }} alt="" />
                                    </div>
                                </li>
                                <li>
                                    <div class="rounded-md overflow-hidden relative w-full h-20">
                                        <div class="absolute w-full h-3/4 -bottom-12 z-10">
                                        </div>
                                        <img src="/assets/images/Partners/uni2.png" class="absolute w-full h-full object-contain" alt="" style={{ width: '100px' }} />
                                    </div>
                                </li>
                                <li>
                                    <div class="rounded-md overflow-hidden relative w-full h-20">
                                        <div class="absolute w-full h-3/4 -bottom-12 z-10">
                                        </div>
                                        <img src="/assets/images/Partners/Footer.png" class="absolute w-full h-full object-contain" alt="" style={{ width: '100px' }} />
                                    </div>
                                </li>
                                <li>
                                    <div class="rounded-md overflow-hidden relative w-full h-20">
                                        <div class="absolute w-full h-3/4 -bottom-12 z-10">
                                        </div>
                                        <img src="/assets/images/Partners/skills and learning.png" class="absolute w-full h-full object-contain" style={{ width: '100px' }} alt="" />
                                    </div>
                                </li>
                                {/* <li>
                                <div class="rounded-md overflow-hidden relative w-full h-36">
                                    <div class="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10">
                                    </div>
                                    <img src="../assets/images/category/development.jpg" class="absolute w-full h-full object-cover" alt="" />
                                </div>
                            </li> */}
                            </ul>
                        </div>

                        {/* <a class="absolute bg-white top-16 flex items-center justify-center p-2 -left-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="previous"> <i class="icon-feather-chevron-left"></i></a>
                    <a class="absolute bg-white top-16 flex items-center justify-center p-2 -right-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="next"> <i class="icon-feather-chevron-right"></i></a> */}
                        <a class="absolute bg-white top-10 flex items-center justify-center p-2 -left-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="previous"> <i class="icon-feather-chevron-left"></i></a>
                        <a class="absolute bg-white top-10 flex items-center justify-center p-2 -right-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="next"> <i class="icon-feather-chevron-right"></i></a>

                    </div>
                </div>
            </>
        );
    }
}

export default Home;