import React, { Component } from 'react';

class DynamicSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputVal: props.value ? props.value : "",
        }
    }

    onChangeValue = async (event) => {
        const { onChange } = this.props;
        const name = event.target.name;
        const value = event.target.value.replace(/\\/g, "");
        await this.setState({ [name]: value });
        onChange && onChange(event);
    }

    render() {
        const { Options, value, onOptionsSelect, onRemoveOptions, onChange, labelName, keyName, placeholder, schema, isInputHide } = this.props;
        const { inputVal } = this.state;
        let schemaLabel = 'label';
        let schemaValue = 'value';
        let matchWith = schemaValue;
        if (schema) {
            if (schema?.label) {
                schemaLabel = schema?.label;
            }
            if (schema?.value) {
                schemaValue = schema?.value
            }
            if (schema?.isMatchWithLabel) {
                matchWith = schemaLabel
            }
        }

        return (
            <div className="grid grid-cols-1 border-2 border-gray-100 p-3 bg-gray-200 bg-opacity-20 rounded">
                <label for="degrees" className="col-span-1 place-content-center font-semibold">{labelName || "Name"} </label>
                <div className="col-span-2">
                    {
                        !isInputHide &&
                        <div className="flex flex-row flex-auto mb-3">
                            <input type="text" name={"inputVal"} onFocus={() => { this.setState({ inputVal: "" }) }} className="with-border md:focus:bg-white" value={inputVal} onChange={this.onChangeValue} placeholder={placeholder || "Degree"} onBlur={() => { inputVal != value && this.setState({ inputVal: value }) }} autoComplete="off" />
                            <button type="button" className={`ml-2 py-2 px-4 bg-${(!Boolean(value || inputVal)) ? "gray" : "red"}-500 text-white font-semibold rounded-lg shadow-md hover:bg-${(!Boolean(value || inputVal)) ? "gray" : "red"}-700 focus:outline-none`} disabled={!(Boolean(value || inputVal))} onClick={() => { this.setState({ inputVal: "" }); onRemoveOptions && onRemoveOptions() }}>
                                <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" data-svg="close-icon">
                                    <line fill="none" stroke="#fff" stroke-width="2" x1="1" y1="1" x2="13" y2="13"></line>
                                    <line fill="none" stroke="#fff" stroke-width="2" x1="13" y1="1" x2="1" y2="13"></line>
                                </svg>
                            </button>
                        </div>
                    }
                    <div className="shadow-none with-border">
                        <div className="flex flex-row flex-wrap mt-2">
                            {
                                Options && Options.map((optionDetails, index) => {
                                    let findValue = false;
                                    if (!isInputHide && Boolean(inputVal)) {
                                        let Regex = new RegExp(`${inputVal.trim()}`, 'i');
                                        findValue = optionDetails[schemaValue].search(Regex) > -1;
                                    }
                                    let selectedClass = "bg-purple-900 text-white";
                                    let suggestClass = "tag-hover";
                                    let displayClass = "border-2 border-light-gray-400";
                                    let AddClass = value == optionDetails[matchWith] ? selectedClass : findValue ? suggestClass : displayClass;
                                    return (
                                        <div key={index} onClick={() => { this.setState({ inputVal: optionDetails[schemaLabel] }); onOptionsSelect && onOptionsSelect(optionDetails[matchWith], { ...optionDetails, index }, index) }} className={`rounded-md py-1 px-3 items-center justify-center flex mr-2 mb-2 cursor-pointer ${AddClass}`} style={{ textTransform : 'capitalize' }}>{optionDetails[schemaLabel]}</div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default DynamicSelection;