import React, { Component } from 'react';
import DynamicSelection from '../../Components/DynamicSelection';
import { appendScript } from '../../Utilities/appendScript';
import Constants from '../../Utilities/Constants';


class MySkill extends Component {

    constructor(props) {
        super(props)
        this.state = {
            degree: {
                value: "",
                selectedOptions: "",
                options: {
                    "school": [
                        "GCSE A-C",
                        "GCSE D-G",
                        "BTEC Firsts",
                        "Intermediate Apprenticeship"
                    ],
                    "college": [
                        "A Level",
                        "BTEC Nationals",
                        "International Baccalaureate",
                        "Advanced Apprenticeships"
                    ],
                    "university": ["Undergraduate Degree BA, BSC", "Foundation Degree FdA, FdSc", "Higher National Diploma HND", "Higher National Certificate HNC", "Higher Apprenticeships", "Degree Apprenticeships", "Master's Degree MA, MSc", "Doctorate PhD"]
                },
                label: "Degrees",
                placeholder: "Degrees",
            },
            MySkillDetails: {
                // degree: {
                //     value: "",
                //     options: [
                //         {
                //             label: "PhD",
                //             value: "PhD"
                //         },
                //         {
                //             label: "BSc",
                //             value: "BSc"
                //         },
                //         {
                //             label: "MSc",
                //             value: "MSc"
                //         },
                //         {
                //             label: "B.Eng",
                //             value: "BEng"
                //         },
                //         {
                //             label: "Found. Degree HND",
                //             value: "found_hnd"
                //         },
                //         {
                //             label: "HVC",
                //             value: "HNC"
                //         },
                //         {
                //             label: "Adv. Diploma",
                //             value: "adv_diploma"
                //         },
                //         {
                //             label: "GCSE Diploma",
                //             value: "gcse_diploma"
                //         },
                //     ],
                //     label: "Degrees",
                //     placeholder: "Degrees",
                // },
                preferOnlineTrainingList: {
                    value: "",
                    options: [
                        {
                            label: "Prefer online training",
                            value: "Prefer online training"
                        },
                        {
                            label: "Mixed F2F/online",
                            value: "Mixed F2F/online"
                        },
                        {
                            label: "Mostly F2F",
                            value: "Mostly F2F"
                        },
                    ],
                    label: "Prefer Online Training",
                    placeholder: "Prefer Online Training",
                },
                typeofDesiredTraining: {
                    value: "",
                    options: [
                        {
                            label: "Mainly theorical, I need basic knowledge",
                            value: "Mainly theorical, I need basic knowledge"
                        },
                        {
                            label: "I'd like some practical lessons",
                            value: "I'd like some practical lessons"
                        },
                        {
                            label: "Mainly practical, I think I already hold the theory",
                            value: "Mainly practical, I think I already hold the theory"
                        },
                    ],
                    label: "Type of Desired Training",
                    placeholder: "Type of Desired Training",
                },
                lowWorkload: {
                    value: "",
                    options: [
                        {
                            label: "Low Workload: I am quite busy",
                            value: "Low Workload: I am quite busy"
                        },
                        {
                            label: "I can put some time daily",
                            value: "I can put some time daily"
                        },
                        {
                            label: "I really want to dedicate a high amount of time on a regular basis",
                            value: "I really want to dedicate a high amount of time on a regular basis"
                        },
                    ],
                    label: "Low Workload",
                    placeholder: "Low Workload",
                },
                immediateAvailability: {
                    value: "",
                    options: [
                        {
                            label: "Immediate Availability",
                            value: "Immediate Availability"
                        },
                        {
                            label: "I am on too many things, I need a short team commitment",
                            value: "I am on too many things, I need a short team commitment"
                        },
                        {
                            label: "Searching for a mid/long-term training",
                            value: "Searching for a mid/long-term training"
                        },
                    ],
                    label: "Immediate Availability",
                    placeholder: "Immediate Availability",
                },
            },
            QuestionAnswer: Constants.skill_questions,
            loading: true,
            user: null,
            SearchText: "",
            redirect: false,
            model: false
        }
        // console.log(QuestionAnswer.questionAnswer)
    }

    componentDidMount = async () => {
        this.getUser();
        // setTimeout(async () => {
        await appendScript('/assets/js/load-select.js');
        // }, 1000)
    }

    getUser = async () => {

        let user = localStorage.getItem('user');
        user = JSON.parse(user);
        if (user) {
            console.log("sele: ", user.skills.degree.type, user.skills.degree.value);
            this.setState({ loading: false, user: user, degree: { ...this.state.degree, selectedOptions: user.skills.degree.type, value:  user.skills.degree.value} });
            // console.log("found user: ", user)
        }
        else {
            this.setState({ loading: false, user: null, redirect: true })
        }
    }

    onSetSkillValue = (key, value) => {
        let { MySkillDetails, user } = this.state;
        MySkillDetails[key] = { ...MySkillDetails[key], value: value };
        user.skills[key].value = value;
        this.setState({ MySkillDetails, user });
    }

    onSetOptionValue = (key, value) => {
        let { QuestionAnswer, user } = this.state;
        console.log(QuestionAnswer[key], value);
        QuestionAnswer[key] = { ...QuestionAnswer[key], answer: value };
        user.skill_questions[key].answer.value = value.value;
        this.setState({ QuestionAnswer, user });
    }

    onChangeValue = async (event) => {
        const name = event.target.name;
        const value = event.target.value.replace(/\\/g, "");
        await this.setState({ [name]: value });
    }


    onSaveUser = async () => {

        let { user, error, SearchText, degree } = this.state;
        let userID = user._id
        this.setState({ saving: true });

        user.skills.degree.type = degree.selectedOptions;
        user.skills.degree.value = SearchText;

        fetch(Constants.url + `users/${userID}`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(user)
            })
            .then((response) => response.json())
            .then((responseData) => {
                console.log(responseData)
                if (responseData.status) {
                    localStorage.setItem('user', JSON.stringify(user));
                    alert("User Details Successfully Updated.")
                    this.setState({ saving: false });

                } else {
                    alert("Something went wrong, Please try again after sometime.");
                    this.setState({ saving: false })
                }
            })
            .catch(err => {
                this.setState({ saving: false })
                alert("Something went wrong, Please try again after sometime.");
                console.log(err);
            });

    }


    render() {
        let { MySkillDetails, QuestionAnswer, user, degree, SearchText } = this.state
        QuestionAnswer = QuestionAnswer.filter(question => question.type === "Skills")
        console.log(QuestionAnswer.length, degree.selectedOptions)
        if (this.state.loading) {
            return (
                <>
                    loading...
                </>
            )
        }
        else {
            return (
                <>
                    <div className="container">
                        <div className="breadcrumb-area py-0 md:mb-10 mb-6 justify-between sm:flex flex-column">
                            <h3 className="md:text-2xl text-lg font-semibold mb-2"> My Skills </h3>
                            <ul class="breadcrumb flex flex-row text-md">
                                <li className="text-md"><a href="demo#/dashboard">Dashboard</a></li>
                                <li className="text-md"><a href="demo#/my-profile">User Profile</a></li>
                                <li className="text-md active">My Skills</li>
                            </ul>
                        </div>
                        {/* <div className="md:space-y-8 space-y-4">
                            <div className="-mb-3 space-y-2">
                                <h1 className="font-semibold md:text-3xl text-xl"> My Skills</h1>
                            </div>
                        </div> */}
                        <div className="grid lg:grid-cols-3 mt-12 gap-8">
                            <div className="bg-white rounded-md lg:shadow-md shadow col-span-3">
                                <div className="grid gap-3 lg:px-6 py-4 pb-0">
                                    <h3 className="font-semibold md:text-2xl text-xl">My info</h3>
                                </div>
                                <div className="grid gap-3 lg:p-6 p-4">

                                    <div className="grid grid-cols-1 border-2 border-gray-100 p-3 bg-gray-200 bg-opacity-20 rounded">
                                        <label for="degrees" className="col-span-1 place-content-center font-semibold">My Qualification </label>
                                        <div className="col-span-2">
                                            <div className="flex flex-row flex-auto mb-3">
                                                <select className="selectpicker" name="selectedOptions" value={this.state.degree.selectedOptions} onChange={(event) => { this.setState({ degree: { ...degree, selectedOptions: event.target.value, value: "" } }); }}>
                                                    <option value="">Select Degree</option>
                                                    <option value="school">School</option>
                                                    <option value="college">College</option>
                                                    <option value="university">University</option>
                                                </select>
                                                {
                                                    Boolean(degree.selectedOptions) &&
                                                    <input type="text" name={"SearchText"} onFocus={() => { this.setState({ SearchText: "" }) }} className="with-border md:focus:bg-white ml-2" value={SearchText} onChange={this.onChangeValue} placeholder={"Search Degree"} onBlur={() => { SearchText != degree.value && this.setState({ SearchText: degree.value }) }} autoComplete="off" />
                                                }

                                                <button type="button" className={`ml-2 py-2 px-4 bg-${(!Boolean(degree.selectedOptions)) ? "gray" : "red"}-500 text-white font-semibold rounded-lg shadow-md hover:bg-${(!Boolean(degree.selectedOptions)) ? "gray" : "red"}-700 focus:outline-none`} disabled={!(Boolean(degree.selectedOptions))} onClick={() => { this.setState({ degree: { ...degree, selectedOptions: "", value: "" }, SearchText: "" }); }}>
                                                    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" data-svg="close-icon">
                                                        <line fill="none" stroke="#fff" stroke-width="2" x1="1" y1="1" x2="13" y2="13"></line>
                                                        <line fill="none" stroke="#fff" stroke-width="2" x1="13" y1="1" x2="1" y2="13"></line>
                                                    </svg>
                                                </button>
                                            </div>
                                            <div className="shadow-none with-border">
                                                <div className="flex flex-row flex-wrap mt-2">
                                                    {
                                                        degree.options[degree.selectedOptions] && degree.options[degree.selectedOptions].map((optionDetails, index) => {
                                                            let findValue = false;
                                                            if (Boolean(SearchText)) {
                                                                let Regex = new RegExp(`${SearchText.trim()}`, 'i');
                                                                findValue = optionDetails.search(Regex) > -1;
                                                            }
                                                            let selectedClass = "bg-purple-900 text-white";
                                                            let suggestClass = "tag-hover";
                                                            let displayClass = "border-2 border-light-gray-400";
                                                            let AddClass = degree.value == optionDetails ? selectedClass : findValue ? suggestClass : displayClass;
                                                            return (
                                                                <div key={index} onClick={() => { this.setState({ degree: { ...degree, value: optionDetails }, SearchText: optionDetails }); }} className={`rounded-md py-1 px-3 items-center justify-center flex mr-2 mb-2 cursor-pointer ${AddClass}`} style={{ textTransform: 'capitalize' }}>{optionDetails}</div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        MySkillDetails && Object.keys(MySkillDetails).length > 0 ?
                                            Object.entries(MySkillDetails).map((Skill, index) => {
                                                const SkillKey = Skill[0];
                                                const SkillData = Skill[1];

                                                return (
                                                    <DynamicSelection
                                                        key={index}
                                                        placeholder={SkillData.placeholder}
                                                        labelName={SkillData.label}
                                                        keyName={SkillKey}
                                                        Options={SkillData.options || []}
                                                        value={user.skills[SkillKey].value}
                                                        onOptionsSelect={(value) => { this.onSetSkillValue(SkillKey, value) }}
                                                        onChange={(event) => { }}
                                                        onRemoveOptions={() => { this.onSetSkillValue(SkillKey, "") }}
                                                    />
                                                )
                                            })
                                            :
                                            <div className="grid grid-cols-1 text-gray-500 font-semibold px-2">No Skills Details</div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="grid lg:grid-cols-3 mt-12 gap-8">
                            <div className="bg-white rounded-md lg:shadow-md shadow col-span-3">
                                <div className="grid gap-3 lg:px-6 py-4 pb-0">
                                    <h3 className="font-semibold md:text-2xl text-xl">Other info</h3>
                                </div>
                                <div className="grid gap-3 lg:p-6 p-4">
                                    {
                                        QuestionAnswer && QuestionAnswer.length > 0 ?
                                            QuestionAnswer.map((option, index) => {
                                                // console.log("index: ", index, user.skill_questions[index])
                                                return (
                                                    <DynamicSelection
                                                        schema={{
                                                            label: "title",
                                                            value: "value",
                                                        }}
                                                        key={index}
                                                        labelName={option.question}
                                                        isInputHide={true}
                                                        keyName={index}
                                                        Options={option.options || []}
                                                        value={user.skill_questions[index]?.answer?.value || ""}
                                                        onOptionsSelect={(value, options) => { this.onSetOptionValue(index, options) }}
                                                        onChange={(event) => { }}
                                                    />
                                                )
                                            })
                                            :
                                            <div className="grid grid-cols-1 text-gray-500 font-semibold px-2">No Options Available</div>
                                    }
                                </div>
                            </div>
                        </div>
                        < br />
                        <div>
                            <button type="button" className="button bg-blue-700" onClick={() => { this.onSaveUser() }}>Save</button>
                        </div>

                    </div>
                </>
            );
        }
    }
}

export default MySkill;