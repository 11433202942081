import React, { Component } from 'react';
import { Route, HashRouter as Router, Redirect, Switch } from 'react-router-dom';
import ProfileSelect from '../Pages/demo/index';
import Header from '../Include/Header';
import Sidebar from '../Include/Sidebar';
// import Store from '../Pages/store';
import MyExperience from '../Pages/MyExperience';
import MySkill from '../Pages/User/MySkills';
import Panel from '../Pages/User/Panel';
import MyQuestionAnswer from '../Pages/MyQuestionAnswer';
import Profile from '../Pages/Profile';
import TrainingPath from '../Pages/demo/TrainingPath';
import MyCareerPath from '../Pages/demo/MyCareerPath';
import Dashboard from '../Pages/User/Dashboard';
import OnlineHelp from '../Pages/User/OnlineHelp';
import TakeSurvey from '../Pages/User/TakeSurvey';
import UserEditor from '../Pages/demo/UserEditor';

export default class demoRoutes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ischeckAuth: false,
			loading: false,
			isLogout: false
		}
		// this.store = Store;
	}

	componentDidMount = async () => {
		const token = await localStorage.getItem('token');
		if (!token) {
			// console.log("Logout")
			// Auth.Logout();
			await this.setState({ isLogout: false, })
		} else {
			await this.setState({ loading: false });
			console.log("Is Token")
		}
	}

	render() {
		if (this.state.loading && this.state.isLogout) {
			return (<Redirect to='/login' />)
		} else if (this.state.loading) {
			return (<div>Loading...</div>);
		}
		else {
			return (
				<Router forceRefresh={true}>
					<div id="wrapper" className="is-verticle">
						<Header />
						<div className="main_content">
							<Switch>
								<Route path="/" exact name="home" component={ProfileSelect}></Route>
								<Route path='/dashboard' name="Dashboard" component={Dashboard}></Route>
								<Route path='/my-experience' name="MyExperience" component={MyExperience}></Route>
								<Route path='/my-skills' name="MySkills" component={MySkill}></Route>
								<Route path='/my-panel' name="MyPenal" component={Panel}></Route>
								<Route path='/my-profile' name="MyProfile" component={Profile}></Route>
								<Route path='/my-answer' name="MyAnswer" component={MyQuestionAnswer}></Route>
								<Route path='/my-training-path' name="MyTrainingPath" component={TrainingPath}></Route>
								<Route path='/my-career-path' name="MyCareerPath" component={MyCareerPath}></Route>
								<Route path='/online-help' name="OnlineHelp" component={OnlineHelp}></Route>
								<Route path='/take-survey' name="TakeSurvey" component={TakeSurvey}></Route>
								<Route path='/user-editor' name="UserEditor" component={UserEditor}></Route>
							</Switch>
						</div>
						<Sidebar />
					</div>
				</Router>
			);
		}
	}
}