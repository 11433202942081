import React, { Component } from 'react';


class Card extends Component {
    constructor(props) {
        super(props);
       
    }
    render() {

        return (
            <>
                {/* // <!--  Categories --> */}

                <li>
                    <div class="rounded-md overflow-hidden relative w-full h-36">
                        <div class="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10">
                        </div>
                        <img src={"/assets/images/category/"+this.props.data.Image} class="absolute w-full h-full object-cover" alt=""/>
                        <div class ="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg"> {this.props.data.title}</div>
                    </div>
                </li>

            </>
        );
    }
}

export default Card;