import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Header extends Component {

    constructor(props) {
        super(props)
        this.state = {
            description: [""],
            loading: true,
            user: {
                name: "Demo User"
            },
            redirect: false
        }
    }

    componentDidMount = async () => {

        setInterval(() => {
            this.getUser();
        }, 1000);
    }

    getUser = async () => {

        let user = localStorage.getItem('user');
        user = JSON.parse(user);
        if (user) {
            this.setState({ loading: false, user });
            // console.log("found user: ", user)
        }
        else {
            this.setState({ loading: false, user: { name: 'Demo User' } })
        }
    }

    render() {
        return (
            <>

                <header className="is-transparent is-dark border-b backdrop-filter backdrop-blur-2xl" uk-sticky="cls-inactive: is-dark is-transparent border-b">
                    <div className="header_inner">
                        <div className="left-side">
                            <div id="logo">
                                <Link to="/">
                                    {/* <img src="/assets/images/logo.png" alt="" />
                                    <img src="/assets/images/logo-light.png" className="logo_inverse" alt="" />
                                    <img src="/assets/images/logo-mobile.png" className="logo_mobile" alt="" /> */}
                                    <span className="text-white">Virtual Skills</span>
                                </Link>
                            </div>
                            <div className="triger" uk-toggle="target: #wrapper ; cls: is-active">
                            </div>
                        </div>
                        <div className="right-side">
                            <div className="header_search">
                                <i className="uil-search-alt"></i>
                                <input value="" type="text" className="form-control search" placeholder=" Quick search for anything.." autoComplete="off" />
                                <div uk-drop="mode: click;offset:10" className="header_search_dropdown">

                                    <h4 className="search_title"> Recently </h4>
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <img src="/assets/images/avatars/avatar-1.jpg" alt="" className="list-avatar" />
                                                <div className="list-name"> Erica Jones </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <img src="/assets/images/avatars/avatar-2.jpg" alt="" className="list-avatar" />
                                                <div className="list-name"> Coffee  Addicts </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <img src="/assets/images/avatars/avatar-3.jpg" alt="" className="list-avatar" />
                                                <div className="list-name"> Mountain Riders </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <img src="/assets/images/avatars/avatar-4.jpg" alt="" className="list-avatar" />
                                                <div className="list-name"> Property Rent And Sale  </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <img src="/assets/images/avatars/avatar-5.jpg" alt="" className="list-avatar" />
                                                <div className="list-name"> Erica Jones </div>
                                            </a>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            <div>


                                <a href="#" className="header_widgets">
                                    Discover
                                </a>
                                <a href="#" className="header_widgets">
                                    Meetups
                                </a>
                                <a href="#" className="header_widgets">
                                    News
                                </a>
                                <a href="#" className="header_widgets">
                                    Contact
                                </a>
                                <a href="#" className="header_widgets">
                                    <ion-icon name="mail-outline" class="is-icon"></ion-icon>
                                </a>
                                <div uk-drop="mode: click" className="header_dropdown">
                                    <div className="drop_headline">
                                        <h4>Notifications </h4>
                                        <div className="btn_action">
                                            <div className="btn_action">
                                                <a href="#">
                                                    <ion-icon name="settings-outline" uk-tooltip="title: Notifications settings ; pos: left"></ion-icon>
                                                </a>
                                                <a href="#">
                                                    <ion-icon name="checkbox-outline" uk-tooltip="title: Mark as read all ; pos: left"></ion-icon>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <ul className="dropdown_scrollbar" data-simplebar>
                                        <li>
                                            <a href="#">
                                                <div className="drop_avatar"> <img src="/assets/images/avatars/avatar-1.jpg" alt="" />
                                                </div>
                                                <div className="drop_content">
                                                    <p> <strong>Adrian Mohani</strong> Like Your Comment On Course
                                                        <span className="text-link">Javascript Introduction </span>
                                                    </p>
                                                    <span className="time-ago"> 2 hours ago </span>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <div className="drop_avatar"> <img src="/assets/images/avatars/avatar-2.jpg" alt="" />
                                                </div>
                                                <div className="drop_content">
                                                    <p>
                                                        <strong>Stella Johnson</strong> Replay Your Comments in
                                                        <span className="text-link">Programming for Games</span>
                                                    </p>
                                                    <span className="time-ago"> 9 hours ago </span>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <div className="drop_avatar"> <img src="/assets/images/avatars/avatar-3.jpg" alt="" />
                                                </div>
                                                <div className="drop_content">
                                                    <p>
                                                        <strong>Alex Dolgove</strong> Added New Review In Course
                                                        <span className="text-link">Full Stack PHP Developer</span>
                                                    </p>
                                                    <span className="time-ago"> 12 hours ago </span>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <div className="drop_avatar"> <img src="/assets/images/avatars/avatar-1.jpg" alt="" />
                                                </div>
                                                <div className="drop_content">
                                                    <p>
                                                        <strong>Jonathan Madano</strong> Shared Your Discussion On Course
                                                        <span className="text-link">Css Flex Box </span>
                                                    </p>
                                                    <span className="time-ago"> Yesterday </span>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                    <a href="#" className="see-all">See all</a>
                                </div>
                                {/* <div uk-drop="mode: click" className="header_dropdown">
                                    <div className="drop_headline">
                                        <h4>Messages </h4>
                                        <div className="btn_action">
                                            <a href="#">
                                                <ion-icon name="settings-outline" uk-tooltip="title: Message settings ; pos: left"></ion-icon>
                                            </a>
                                            <a href="#">
                                                <ion-icon name="checkbox-outline" uk-tooltip="title: Mark as read all ; pos: left"></ion-icon>
                                            </a>
                                        </div>
                                    </div>
                                    <ul className="dropdown_scrollbar" data-simplebar>
                                        <li>
                                            <a href="#">
                                                <div className="drop_avatar"> <img src="/assets/images/avatars/avatar-1.jpg" alt="" />
                                                </div>
                                                <div className="drop_content">
                                                    <strong> John menathon </strong> <span className="time"> 6:43 PM</span>
                                                    <p> Lorem ipsum dolor sit amet, consectetur </p>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <div className="drop_avatar"> <img src="/assets/images/avatars/avatar-2.jpg" alt="" />
                                                </div>
                                                <div className="drop_content">
                                                    <strong> Zara Ali </strong> <span className="time">12:43 PM</span>
                                                    <p> Lorem ipsum dolor sit amet, consectetur </p>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <div className="drop_avatar"> <img src="/assets/images/avatars/avatar-3.jpg" alt="" />
                                                </div>
                                                <div className="drop_content">
                                                    <strong> Mohamed Ali </strong> <span className="time"> Wed</span>
                                                    <p> Lorem ipsum dolor sit amet, consectetur </p>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <div className="drop_avatar"> <img src="/assets/images/avatars/avatar-1.jpg" alt="" />
                                                </div>
                                                <div className="drop_content">
                                                    <strong> John menathon </strong> <span className="time"> Sun </span>
                                                    <p> Lorem ipsum dolor sit amet, consectetur </p>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <div className="drop_avatar"> <img src="/assets/images/avatars/avatar-2.jpg" alt="" />
                                                </div>
                                                <div className="drop_content">
                                                    <strong> Zara Ali </strong> <span className="time"> Fri </span>
                                                    <p> Lorem ipsum dolor sit amet, consectetur </p>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <div className="drop_avatar"> <img src="/assets/images/avatars/avatar-3.jpg" alt="" />
                                                </div>
                                                <div className="drop_content">
                                                    <strong> Mohamed Ali </strong> <span className="time">1 Week ago</span>
                                                    <p> Lorem ipsum dolor sit amet, consectetur </p>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                    <a href="#" className="see-all">See all</a>
                                </div> */}


                                <a href="#" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <img src="/assets/images/avatars/placeholder.png" className="header_widgets_avatar" alt="" style={{}} />
                                    <div class='px-3'>
                                        {this.state.user.name || "xavier"}
                                    </div>
                                </a>
                                <div uk-drop="mode: click;offset:5" className="header_dropdown profile_dropdown">
                                    <ul>
                                        <li>
                                            <a href="#" className="user">
                                                <div className="user_avatar">
                                                    <img src="/assets/images/avatars/avatar-2.jpg" alt="" />
                                                </div>
                                                <div className="user_name">
                                                    <div> Stella Johnson </div>
                                                    <span> @Johnson </span>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <hr />
                                        </li>
                                        {/* <li>
                                            <a href="#" className="is-link">
                                                <ion-icon name="rocket-outline" className="is-icon"></ion-icon> <span>  Upgrade Membership  </span>
                                            </a>
                                        </li>
                                        <li>
                                            <hr />
                                        </li> */}
                                        <li>
                                            <a href="page-setting.html">
                                                <ion-icon name="person-circle-outline" class="is-icon"></ion-icon>
                                                My Account
                                            </a>
                                        </li>
                                        <li>
                                            <a href="group-feed.html">
                                                <ion-icon name="card-outline" class="is-icon"></ion-icon>
                                                Subscriptions
                                            </a>
                                        </li>
                                        <li>
                                            <a href="group-feed.html">
                                                <ion-icon name="color-wand-outline" class="is-icon"></ion-icon>
                                                My Billing
                                            </a>
                                        </li>
                                        <li>
                                            <a href="group-feed.html">
                                                <ion-icon name="settings-outline" class="is-icon"></ion-icon>
                                                Account Settings
                                            </a>
                                        </li>
                                        <li>
                                            <hr />
                                        </li>
                                        <li>
                                            <a href="#" id="night-mode" className="btn-night-mode" onClick="UIkit.notification({ message: 'Hmm...  <strong> Night mode </strong> feature is not available yet. ' , pos: 'bottom-right'  })">
                                                <ion-icon name="moon-outline" className="is-icon"></ion-icon>
                                                Night mode
                                                <span className="btn-night-mode-switch">
                                                    <span className="uk-switch-button"></span>
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="group-feed.html">
                                                <ion-icon name="log-out-outline" class="is-icon"></ion-icon>
                                                Log Out
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                            </div>

                        </div>
                    </div>
                </header>
            </>
        );
    }
}

export default Header;