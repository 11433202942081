import React, { Component } from 'react';
import { Bounce } from "react-activity";
import "react-activity/dist/Bounce.css";
import { Redirect } from 'react-router-dom';
import CareerPath from '../../Components/Chart/CareerPath';
import CareerPath1 from '../../Components/Chart/CareerPath1';

class MyCareerPath extends Component {
    constructor(props) {
        super(props);
        this.state = {
            model: false,
            loading: true,
            user: null,
            redirect: false,
            CareerList: {
                Step1: {
                    name: "Junior Java Developer",
                    className: "bg-blue-600",
                    icon: {
                        name: "checkmark-outline",
                        className: "bg-green-900",
                        title: "Natural progression – coherent"
                    },
                    startToEndYear: "Jan 2018 – Jan 2019",
                },
                Step2: {
                    name: "Junior Support Officer",
                    className: "bg-blue-600",
                    icon: {
                        name: "checkmark-outline",
                        className: "bg-green-900",
                        title: "Natural progression – coherent"
                    },
                    startToEndYear: "Feb 2019 - Jan 2020",
                },
                Step3: {
                    name: "Junior Devops Engineer",
                    className: "bg-blue-600",
                    icon: {
                        name: "warning-sharp",
                        className: "bg-yellow-600",
                        title: "Uncoherent progression - event"
                    },
                    startToEndYear: "Feb 2020 - Current"
                },
                Step4: {
                    name: "Full Stack Web & App Developer",
                    className: "bg-yellow-400",
                    icon: {
                        name: "heart-outline",
                        className: "bg-red-700",
                        title: "Desired next step - validated"
                    },
                    ModelDetails: {
                        title: 'Wiki',
                        content: "What Is a Full Stack Java Developer? A full stack Java developer is a web developer that uses the Java language in the development of the -entire technology stack of a web-based application.",
                        subContent: "Here are Full Stack Developer skills that are non-negotiable!",
                        details: [
                            "HTML/CSS. While HTML stands for Hypertext Markup Language, CSS stands for Cascading Style Sheets",
                            "JavaScript",
                            "Git and GitHub",
                            "Backend languages.",
                            "Web architecture.",
                            "HTTP and REST.",
                            "Database storage.",
                            "Basic design skills.",
                        ],
                        questions: "What are the skills required for Java full stack developer?",
                        link: 'https://www.webenrol.com/skillsandlearningace/?page=detail&courseCode=AT1923A',
                    }
                },
                Step5: {
                    name: "Senior Java Full Stack Dev",
                    className: "bg-yellow-400",
                    icon: {
                        name: "heart-outline",
                        className: "bg-red-700",
                        title: "Desired next step - validated"
                    },
                },
                Step6: {
                    name: "Scrum Manager",
                    className: "bg-yellow-400",
                    icon: {
                        name: "heart-dislike-sharp",
                        className: "bg-yellow-700",
                        title: "Potential next step – not validated"
                    },
                },
                // Step7: {
                //     name: "Scrum Manager",
                //     className: "bg-yellow-400",
                //     icon: {
                //         name: "heart-dislike-sharp",
                //         className: "bg-yellow-700",
                //         title: "Potential next step – not validated"
                //     },
                // },
                // Step8: {
                //     name: "Scrum Manager",
                //     className: "bg-yellow-400",
                //     icon: {
                //         name: "heart-dislike-sharp",
                //         className: "bg-yellow-700",
                //         title: "Potential next step – not validated"
                //     },
                // }
            },
            Career1List: {
                Step1: {
                    name: "BA Marketing",
                    className: "bg-blue-600",
                    icon: {
                        name: "checkmark-outline",
                        className: "bg-green-900",
                        title: "Natural progression – coherent"
                    },
                    startToEndYear: "Sept 1989 – June 1993",
                },
                Step2: {
                    name: "MA Marketing Management",
                    className: "bg-blue-600",
                    icon: {
                        name: "checkmark-outline",
                        className: "bg-green-900",
                        title: "Natural progression – coherent"
                    },
                    startToEndYear: "Sept 1994 – June 1995",
                    ModelDetails: {
                        title: 'Wiki',
                        content: "What Is a Full Stack Java Developer? A full stack Java developer is a web developer that uses the Java language in the development of the -entire technology stack of a web-based application.",
                        subContent: "Here are Full Stack Developer skills that are non-negotiable!",
                        details: [
                            "HTML/CSS. While HTML stands for Hypertext Markup Language, CSS stands for Cascading Style Sheets",
                            "JavaScript",
                            "Git and GitHub",
                            "Backend languages.",
                            "Web architecture.",
                            "HTTP and REST.",
                            "Database storage.",
                            "Basic design skills.",
                        ],
                        questions: "What are the skills required for Java full stack developer?",
                        link: 'https://www.webenrol.com/skillsandlearningace/?page=detail&courseCode=AT1923A',
                    }
                },
                Step3: {
                    name: "Everything Digital & IT Level 2",
                    className: "bg-blue-600",
                    icon: {
                        name: "warning-sharp",
                        className: "bg-yellow-600",
                        title: "Uncoherent progression - event"
                    },
                    startToEndYear: "Sept 2014 – April 2015"
                },
                Step4: {
                    name: "Digital Skills Bootcamp",
                    className: "bg-yellow-400",
                    icon: {
                        name: "heart-outline",
                        className: "bg-red-700",
                        title: "Desired next step - validated"
                    },
                },
                Step5: {
                    name: "MA Marketing Communication",
                    className: "bg-yellow-400",
                    icon: {
                        name: "heart-dislike-sharp",
                        className: "bg-yellow-700",
                        title: "Potential next step – not validated"
                    },
                }
            }
        }
    }

    componentDidMount = async () => {
        await this.getUser();
    }

    getUser = async () => {

        let user = localStorage.getItem('user');
        user = JSON.parse(user);
        if (user) {
            // this.setState({ user, careerChart: user.careerChart, CareerList: user.careerPath });
            this.setState({ user, careerChart: "ca2", CareerList: user.careerPath });
            console.log("found user: ", user)
            setTimeout(() => {
                this.setState({ loading: false })
            }, 5000)
        }
        else {
            this.setState({ loading: false, user: null, redirect: true })
        }
    }

    render() {
        return (
            <div className="container">
                {
                    this.state.redirect &&
                    <Redirect path="/" />
                }
                <div className="breadcrumb-area py-0 md:mb-10 mb-6 justify-between sm:flex flex-column">
                    <h3 className="md:text-2xl text-lg font-semibold mb-2"> My Career Path </h3>
                    <ul className="breadcrumb flex flex-row text-md">
                        <li className="text-md"><a href="demo#/dashboard">Dashboard</a></li>
                        {/* <li className="text-md"><a href="demo#/my-profile">User Profile</a></li> */}
                        <li className="text-md active">My Career Path</li>
                    </ul>
                </div>
                {
                    this.state.loading ?
                        <div className="bg-white rounded-md lg:shadow-md shadow col-span-3 h-52 flex justify-center align-items-center flex-column">
                            <div className="">
                                <Bounce color="#727981" size={32} speed={1} animating={true} />
                            </div>
                            <div className="pt-6 text-2xl font-semibold text-black">
                                Build Career Path
                            </div>
                        </div>
                        :
                        <>
                            {
                                // jaymie myir
                                this.state.careerChart == "ca1" &&
                                <CareerPath ChartData={this.state.CareerList} />
                            }
                            {
                                // 4 horizontal and 2 vertical
                                this.state.careerChart == "ca2" &&
                                <CareerPath1 ChartData={this.state.CareerList} />
                            }
                        </>
                }
            </div>
        );
    }
}

export default MyCareerPath;