import React, { Component } from 'react';
import Experience from '../../Components/Profile/Experience';

class Panel extends Component {

    constructor(props) {
        super(props)
        this.state = {
            description: [""],
            loading: true,
            user: null,
            redirect: false
        }
    }

    componentDidMount = async () => {
        await this.getUser();
    }

    getUser = async () => {

        let user = localStorage.getItem('user');
        user = JSON.parse(user);
        if (user) {
            this.setState({ loading: false, user });
            // console.log("found user: ", user)
        }
        else {
            this.setState({ loading: false, user: null, redirect: true })
        }
    }

    onAddDes() {
        let { description } = this.state
        description.push("");
        this.setState({ description })
    }
    onChangeDescription = (event, index, keyName) => {
        let array = this.state[keyName];
        if (!isNaN(index)) {
            let value = event.target.value;
            array[index] = value;
            this.setState({ [keyName]: array })
        }
    }
    render() {
        let { user } = this.state

        if (this.state.loading) {
            return (
                <>
                    loading...
                </>
            )
        }
        else {

            return (
                <>
                    <div className="container">

                        <div className="breadcrumb-area py-0 md:mb-10 mb-6 justify-between sm:flex flex-column">
                            <h3 className="md:text-2xl text-lg font-semibold mb-2"> My Panel </h3>
                            <ul class="breadcrumb flex flex-row text-md">
                                <li className="text-md"><a href="demo#/dashboard">Dashboard</a></li>
                                <li className="text-md"><a href="demo#/my-profile">User Profile</a></li>
                                <li className="text-md active">My Panel</li>
                            </ul>
                        </div>
                        <div className="grid lg:grid-cols-3 gap-2">
                            {/* <div>
                            <h3 className="text-xl mb-2 font-semibold"> Basic</h3>
                            <p> Lorem ipsum dolor sit amet nibh consectetuer adipiscing elit</p>
                        </div> */}
                            {/* <div className="row justify-content-center align-items-center">
                            </div> */}
                            <div className="row col-span-3">
                                <div className="col-md-2 mt-8 mb-5 flex justify-center">
                                    <img src="../assets/images/avatars/placeholder.png" alt="" className=" rounded-full w-20 mb-4" />
                                </div>
                                <div className="col-md-10 mt-5 pt-2">
                                    <div className="text-2xl text-black">
                                        {this.state.user.name}
                                    </div>
                                    <div className="pt-2 text-base text-black-100">{this.state.user.description}</div>
                                </div>
                            </div>
                            <div className="bg-white rounded-md lg:shadow-md shadow col-span-3">
                                <div className="lg:p-6 p-4">

                                    <div className="row" style={{ paddingBottom: 20, borderBottomWidth: 1 }}>
                                        <div className="col-md-8">
                                            <span>
                                                Level of profile accuracy
                                            </span>
                                            <div id="linear-gauge">
                                                <img src="/assets/images/guage.png" style={{ maxWidth: '50%' }} alt="guage" />
                                            </div>
                                        </div>
                                        <div className="col-md-4" style={{ textAlign: 'right', margin: "auto" }}>
                                            <img src={`../assets/images/${user.myPanel.badge_image}`} alt="" style={{ display: 'inline', marginRight: 20 }} className="h-10 w-10" />
                                            <span>
                                                {user.myPanel.badge_text}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mt-12">
                                        <div className="col-md-12" style={{ textAlign: 'center', display: 'flex' }}>
                                            <div className="col-md-4" style={{ borderRightColor: '#dbdada', borderRightWidth: 0.5 }}>
                                                <h2 style={{ fontSize: 36, fontWeight: 600, textAlign: 'center' }}>
                                                    {user.myPanel.visualised_trainings}
                                                </h2>
                                                <span style={{ fontSize: 14, textAlign: 'center' }}>
                                                    Visualised Trainings
                                                </span>
                                            </div>
                                            <div className="col-md-4" style={{ borderRightColor: '#dbdada', borderRightWidth: 0.5 }}>
                                                <h2 style={{ fontSize: 36, fontWeight: 600, textAlign: 'center' }}>
                                                {user.myPanel.attended_trainings}
                                                </h2>
                                                <span style={{ fontSize: 14, textAlign: 'center' }}>
                                                    Attended Trainings
                                                </span>
                                            </div>
                                            <div className="col-md-4">
                                                <h2 style={{ fontSize: 36, fontWeight: 600, textAlign: 'center' }}>
                                                {user.myPanel.saved_trainings}
                                                </h2>
                                                <span style={{ fontSize: 14, textAlign: 'center' }}>
                                                    Saved Searches
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-12">
                                        <div className="col-md-12" style={{ textAlign: 'center', }}>
                                            <span style={{ fontSize: 28, textAlign: 'center' }}>
                                                My current desired role is:
                                            </span>
                                        </div>
                                        <div className="col-md-12 mt-5">
                                            <div className="col-md-12" style={{ textAlign: 'center', padding: 10, borderWidth: 0.5, borderStyle: 'dashed', borderRadius: 5, borderColor: '#361A54' }}>
                                                <span style={{ fontSize: 22, textAlign: 'center', color: '#361A54' }}>
                                                    {user.role}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-10">
                                        <div className="col-md-12" style={{ textAlign: 'left', }}>
                                            <a href="https://www.ucas.com/ucas/tariff-calculator" target="_blank" type="button" className="button bg-red-500" style={{ backgroundColor: 'rgba(239, 68, 68, 500)' }}> UCAS points calculator
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    }
}

export default Panel;