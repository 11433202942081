import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Constants from '../Utilities/Constants';
import { appendScript } from '../Utilities/appendScript';


class Profile extends Component {

    constructor(props) {
        super(props)
        this.state = {
            description: [""],
            loading: true,
            saving: false,
            user: null,
            redirect: false
        }
    }
    componentDidMount = async () => {
        this.getUser();
        await appendScript('/assets/js/load-select.js');
    }

    getUser = async () => {

        let user = localStorage.getItem('user');
        user = JSON.parse(user);
        if (user) {
            this.setState({ loading: false, user: user });
            console.log("found user: ", user)
        }
        else {
            this.setState({ loading: false, user: null, redirect: true })
        }
    }

    onChangeDescription = (event, index, keyName) => {
        let array = this.state[keyName];
        if (!isNaN(index)) {
            let value = event.target.value;
            array[index] = value;
            this.setState({ [keyName]: array })
        }
    }

    onChangeInput = async (event) => {
        const name = event.target.name;
        const value = event.target.value.replace(/\\/g, "");

        let { user } = this.state;
        user[name] = value;

        await this.setState({ user });
    }


    onSaveUser = async () => {

        let { user, error } = this.state;
        let userID = user._id
        this.setState({ saving: true });

        fetch(Constants.url + `users/${userID}`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(user)
            })
            .then((response) => response.json())
            .then((responseData) => {
                console.log(responseData)
                if (responseData.status) {
                    localStorage.setItem('user', JSON.stringify(user));
                    alert("User Details Successfully Updated.")
                    this.setState({ saving: false });

                } else {
                    alert("Something went wrong, Please try again after sometime.");
                    this.setState({ saving: false })
                }
            })
            .catch(err => {
                this.setState({ saving: false })
                alert("Something went wrong, Please try again after sometime.");
                console.log(err);
            });

    }

    render() {
        let { description, user } = this.state
        if (this.state.loading) {
            return (
                <>
                    loading...
                </>
            )
        }
        else {
            return (
                <>
                    <div className="container">
                        <div className="breadcrumb-area py-0 md:mb-10 mb-6 justify-between sm:flex flex-column">
                            <h3 className="md:text-2xl text-lg font-semibold mb-2"> My Profile </h3>
                            <ul class="breadcrumb flex flex-row text-md">
                                <li className="text-md"><a href="demo#/dashboard">Dashboard</a></li>
                                <li className="text-md active">My Profile</li>
                                {/* <li className="text-md active">Online Help</li> */}
                            </ul>
                        </div>

                        <div className="grid lg:grid-cols-3 mt-12 gap-8">
                            {/* <div>
                            <h3 className="text-xl mb-2 font-semibold"> Basic</h3>
                            <p> Lorem ipsum dolor sit amet nibh consectetuer adipiscing elit</p>
                        </div> */}
                            <div className="bg-white rounded-md lg:shadow-md shadow col-span-3">

                                <div className="grid grid-cols-2 gap-3 lg:p-6 p-4">
                                    {/* <div className="col-span-2 flex py-2 space-x-5">
                                    <img src="../assets/images/image-placeholder.jpg" alt="" className="h-16 rounded-full w-16" />
                                    <div className="border font-medium px-3 py-1.5 rounded-md self-center shadow-sm text-center text-sm" uk-htmlForm-custom>
                                        <input type="file" style={{ opacity: 0 }} /> Change
                                    </div>
                                </div> */}
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>

                                        <img src="../assets/images/avatars/placeholder.png" alt="" className="h-20 rounded-full w-20 mb-4" />
                                        <a href="#" className="border font-medium px-3 py-1.5 rounded-md self-center shadow-sm text-center text-sm">
                                            <input type="file" style={{ opacity: 0, position: "absolute", width: 'inherit' }} /> Change
                                        </a>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <span>
                                            Level of profile accuracy
                                        </span>
                                        <img src="/assets/images/guage.png" style={{ maxWidth: '65%' }} alt="guage" />
                                        {/* <div id="linear-gauge" style={{ alignSelf: 'end' }} >
                                    </div> */}
                                    </div>

                                </div>
                                <div className="grid grid-cols-2 gap-3 lg:p-6 p-4">
                                    <div>
                                        <label htmlFor="first-name"> First name</label>
                                        <input type="text" placeholder="" id="first_name" name="first_name" value={user?.first_name || ""} style={{ backgroundColor: 'white' }} className="shadow-none with-border" onChange={(e) => { this.onChangeInput(e) }} />
                                    </div>
                                    <div>
                                        <label htmlFor="last-name"> Last name</label>
                                        <input type="text" placeholder="" id="last_name" name="last_name" value={user?.last_name || ""} className="shadow-none with-border" onChange={(e) => { this.onChangeInput(e) }} />
                                    </div>
                                    <div>
                                        <label htmlFor="email"> Email</label>
                                        <input type="text" placeholder="" id="email" name="email" value={user?.email || ""} className="shadow-none with-border" onChange={(e) => { this.onChangeInput(e) }} />
                                    </div>
                                    <div>
                                        <label htmlFor="age"> Age</label>
                                        <input type="number" placeholder="" id="age" name="age" value={user?.age || ''} className="shadow-none with-border" onChange={(e) => { this.onChangeInput(e) }} />
                                    </div>
                                    <div>
                                        <label htmlFor="gender"> Gender</label>
                                        <input type="text" placeholder="" id="gender" name="gender" value={user?.gender || ''} className="shadow-none with-border" onChange={(e) => { this.onChangeInput(e) }} />
                                    </div>
                                    <div>
                                        <label htmlFor="ethnicity"> Ethnicity</label>
                                        <input type="text" placeholder="" id="ethnicity" name="ethnicity" value={user?.ethnicity || ''} className="shadow-none with-border" onChange={(e) => { this.onChangeInput(e) }} />
                                    </div>

                                    <div className="col-span-2">
                                        <label htmlFor="description">Description</label>
                                        <textarea id="description" name="description" rows="3" value={user?.description || ''} className="with-border" style={{ padding: 10 }} onChange={(e) => { this.onChangeInput(e) }}></textarea>
                                    </div>

                                    <div className="col-span-2 row p-2" style={{ alignItems: 'center', display: 'flex' }}>
                                        <div className="col-md-3">
                                            <label htmlFor="interested" style={{ margin: 0 }}>I am Interested in</label>
                                        </div>
                                        <div className="col-md-9" style={{ paddingRight: 7 }}>
                                            <select className="selectpicker" name="interested" id="interested" onChange={(e) => { console.log(e.target.name, e.target.value); }}>
                                                {
                                                    Constants.user.interested.map((int, id) => {
                                                        return (
                                                            <option key={id} value={`${int.value}`}>{int.title}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>


                                    <div>
                                        <button type="button" className="button bg-blue-700" onClick={() => { this.onSaveUser() }}>Save</button>
                                    </div>

                                </div>


                                <hr style={{ margin: '0px 25px' }} />

                                <div className="md:p-7 p-5  ">

                                    <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-4 gap-2 -m-3">
                                        <Link to={"/my-panel"}>
                                            <div className="hover:bg-gray-100 flex items-start px-3 py-2 rounded-lg space-x-3 skill-hover">
                                                <div className="text-3xl text-white from-red-600 to-red-400 bg-gradient-to-tl p-2 rounded-md md hydrated" role="img" >
                                                    <span className=" icon-material-outline-dashboard">

                                                    </span>
                                                </div>
                                                <div style={{ padding: '8px 5px' }}>
                                                    <h3 className="font-semibold text-lg">My Panel</h3>
                                                </div>
                                            </div>
                                        </Link>
                                        <Link to="/my-skills">
                                            <div className="hover:bg-gray-100 flex items-start px-3 py-2 rounded-lg space-x-3 skill-hover">
                                                <div name="briefcase" className="text-3xl text-white from-blue-600 to-blue-400 bg-gradient-to-tl p-2 rounded-md md hydrated" role="img"  >
                                                    <span className="icon-material-outline-folder-shared">

                                                    </span>
                                                </div>
                                                <div style={{ padding: '8px 5px' }}>
                                                    <h3 className="font-semibold text-lg"> My Skills</h3>
                                                    {/* <div className="flex space-x-2 items-center text-sm pt-0.5">
                                                    <div> 16 Courses </div>
                                                    <div>·</div>
                                                    <div> 523 Students</div>
                                                </div> */}
                                                </div>
                                            </div>
                                        </Link>
                                        <Link to="/">
                                            <div className="hover:bg-gray-100 flex items-start px-3 py-2 rounded-lg space-x-3 skill-hover">
                                                <div name="color-wand" className="text-3xl text-white from-purple-600 to-purple-400 bg-gradient-to-tl p-2 rounded-md md hydrated" role="img" >
                                                    <span className="icon-feather-search">

                                                    </span>
                                                </div>
                                                <div style={{ padding: '8px 5px' }}>
                                                    <h3 className="font-semibold text-lg"> Search New Job</h3>
                                                    {/* <div className="flex space-x-2 items-center text-sm pt-0.5">
                                                    <div> 23 Courses </div>
                                                    <div>·</div>
                                                    <div> 356  Students</div>
                                                </div> */}
                                                </div>
                                            </div>
                                        </Link>
                                        <Link to="/my-answer">
                                            <div className="hover:bg-gray-100 flex items-start px-3 py-2 rounded-lg space-x-3 skill-hover">
                                                <div name="shield-checkmark" className="text-3xl text-white from-yellow-600 to-yellow-400 bg-gradient-to-tl p-2 rounded-md md hydrated" role="img" >
                                                    <span className="icon-feather-check-square">

                                                    </span>
                                                </div>
                                                <div style={{ padding: '8px 5px' }}>
                                                    <h3 className="font-semibold text-lg"> Other Answers</h3>
                                                    {/* <div className="flex space-x-2 items-center text-sm pt-0.5">
                                                    <div> 12 Courses </div>
                                                    <div>·</div>
                                                    <div> 256 Students</div>
                                                </div> */}
                                                </div>
                                            </div>
                                        </Link>
                                        <Link to="/my-experience">
                                            <div className="hover:bg-gray-100 flex items-start px-3 py-2 rounded-lg space-x-3 skill-hover">
                                                <div name="leaf" className="text-3xl text-white from-green-600 to-green-400 bg-gradient-to-tl p-2 rounded-md md hydrated" role="img" >
                                                    <span className="icon-material-outline-school">

                                                    </span>
                                                </div>
                                                <div style={{ padding: '8px 5px' }}>
                                                    <h3 className="font-semibold text-lg"> My Experience</h3>
                                                    {/* <div className="flex space-x-2 items-center text-sm pt-0.5">
                                                    <div> 34 Courses </div>
                                                    <div>·</div>
                                                    <div> 420 Students</div>
                                                </div> */}
                                                </div>
                                            </div>
                                        </Link>
                                        <Link to="">
                                            <div className="hover:bg-gray-100 flex items-start px-3 py-2 rounded-lg space-x-3 skill-hover">
                                                {/* <div name="icon-material-outline-attach-file" className="text-3xl text-white from-pink-600 to-pink-400 bg-gradient-to-tl p-2 rounded-md md hydrated" role="img" ></div> */}
                                                <span className=" text-3xl text-white from-pink-600 to-pink-400 bg-gradient-to-tl p-2 rounded-md md hydrated icon-material-outline-attach-file" role="img" >

                                                </span>
                                                <div>
                                                    <h3 className="font-semibold text-lg"> Upload your CV</h3>
                                                    <div className="flex space-x-2 items-center text-sm pt-0.5">
                                                        <input type="file" id="cv" style={{ height: 'auto', padding: 1 }} className="shadow-none" />
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>

                                {/* <div className="col-span-2">
                                    <label htmlFor="email"> Skills</label>
                                    <input type="text" placeholder="" id="email" className="shadow-none with-border" />
                                </div> */}

                                {/* <Experience /> */}
                                {/* <div className="bg-gray-10 p-6 pt-0 flex justify-end space-x-3">
                                <button className="p-2 px-4 rounded bg-gray-50 text-red-500"> Cancel </button>
                                <button type="button" className="button bg-blue-700"> Save </button>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    }
}

export default Profile;