import React, { Component } from 'react';
import Card from './Card';

class Slider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cards: [{
                Image:"design.jpg",
                title:"Design",
            },
            {
                Image:"design.jpg",
                title:"Marketing",
            },
            {
                Image:"design.jpg",
                title:"Software",
            }, 
             {
                Image:"design.jpg",
                title:"Music",
            },
            {
                Image:"design.jpg",
                title:"Travel",
            },
            {
                Image:"design.jpg",
                title:"Development",
            }],
            
        }

    }
    render() {

        return (
            <>
                {/* // <!--  Categories --> */}
                <div>
                    <div className="relative -mt-3" uk-slider="finite: true">

                        <div className="uk-slider-container px-1 py-3">
                            <ul className="uk-slider-items uk-child-width-1-5@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-small uk-grid">
                                {
                                    this.state.cards.map((value,index ) => {
                                        return (
                                           <Card data={value} />
                                        )
                                    })
                                }
                            </ul>
                        </div>

                        <a className="absolute bg-white top-16 flex items-center justify-center p-2 -left-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="previous"> <i className="icon-feather-chevron-left"></i></a>
                        <a className="absolute bg-white top-16 flex items-center justify-center p-2 -right-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="next"> <i className="icon-feather-chevron-right"></i></a>

                    </div>

                </div>
            </>
        );
    }
}

export default Slider;