import React, { Component } from 'react';
import Slider from './Slider';

class Categories extends Component {

    render() {

        return (
            <>
            {/* // <!--  Categories --> */}
                <div class="container">
                    <div className="sm:my-6 my-3 flex items-end justify-between">
                        <div>
                            <h2 className="text-xl font-semibold"> Categories </h2>
                            <p className="font-medium text-gray-500 leading-6"> Find a topic by browsing top categories. </p>
                        </div>
                        <a href="#" className="text-blue-500 sm:block hidden"> See all </a>
                    </div>
                    <Slider/>
                </div>

                
                  
             
            </>
        );
    }
}

export default Categories;