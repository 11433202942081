import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { JsonEditor as Editor, JsonEditor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

class UserEditor extends Component {

    constructor(props) {
        super(props)
        this.state = {
            description: [""],
            loading: true,
            user: null,
            redirect: false,
            UserData: {}
        }
    }

    async componentDidMount() {
        await this.fetchUserData()
        // console.log("User => ", UserData)
    }

    // fetchUserData = async () => {
    //     const users = await fetch("./assets/Json/User.json")
    //         .then(
    //             function (res) {
    //                 return res.json()
    //             })

    //     this.setState({ UserData: users, loading:false });

    // }


    fetchUserData = async () => {
        const users = await fetch("http://localhost:3000/assets/Json/User.json").then(
                function (res) {
                    return res.json()
                })

                // console.log("NO OUTPUT",users)
        this.setState({ UserData: users, loading:false });

    }

    handleChange = async (e) => {
        console.log(e);   
    }

    render() {
        if (this.state.loading) {
            return(
                <>Loading</>
            )
        } else {    

            return (
                <div>
                    <Editor
                        value={this.state.UserData}
                        onChange={this.handleChange}
                    />
                </div>
            );
        }
    }
}

export default UserEditor;