import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Constants from '../../Utilities/Constants';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            UserData: [],
            loading : true,
        }
    }

    async componentDidMount() {
        await this.fetchUserData()
        // console.log("User => ", UserData)
    }

    fetchUserData = async () => {
        const users = await fetch("https://poc.vsp.xpertie.com/assets/Json/User.json").then(
                function (res) {
                    return res.json()
                })

                // console.log("NO OUTPUT",users)
        this.setState({ UserData: users, loading:false });

    }

    fetchUserData_ = async () => {
        this.setState({ loading: false });
        return
        await fetch(Constants.url + "users", {
            "method": "GET",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json"
            }
        }).then(res => res.json())
            .then((result) => {
                if (result.status) {
                    return this.setState({ UserData: result.users, loading: false });
                }
                else {
                    alert("No Users Available");
                    return this.setState({ loading: false });
                }
            })
            .catch(err => {
                console.log("err: ", err);
                return alert("you are experiencing server error, please try after sometime.")
            })

    }

    selectUser = async (e, user) => {
        e.preventDefault();
        const res = localStorage.setItem('user', JSON.stringify(user));
        window.location.href = "/demo#/dashboard"
        // this.setState({ redirect: true });
    }

    render() {
        const { UserData, loading } = this.state

        if (loading) {
            return (
                <>Loading..</>
            )
        }
        else {

            return (
                <>
                    {/* {
                        this.state.redirect &&
                        <Redirect to='/login' />
                    } */}

                    <div className="container">


                        <div className="breadcrumb-area py-0 md:mb-10 mb-6 justify-between flex flex-column">
                            {/* <div> */}
                            <h3 className="md:text-2xl text-lg font-semibold mb-2"> Profile Selections </h3>
                            {/* <ul class="breadcrumb flex flex-row text-md">
                            <li className="text-md"><a href="#">Dashboard</a></li>
                            <li className="text-md"><a href="#">Pictures</a></li>
                            <li className="text-md"><a href="#">Summer 15</a></li>
                            <li className="text-md active">Italy</li>
                        </ul> */}
                            {/* </div> */}
                            <div className="flex items-center space-x-3 md:mt-0 mt-4">
                                {/* <div className="bg-gray-100 border inline-flex p-0.5 rounded-md text-lg">
                                <a href="#" className="py-1.5 px-2.5 rounded-md" data-tippy-placement="top" data-tippy="" data-original-title="Grid view">
                                    <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                                </a>
                                <a href="#" className="py-1.5 px-2.5 rounded-md bg-white shadow" data-tippy-placement="top" data-tippy="" data-original-title="List view">
                                    <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path></svg>
                                </a>
                            </div> */}
                                {/* <div className="bg-white border flex items-center overflow-hidden relative rounded-lg">
                                <i className="pl-4 -mr-2 relative uil-search"></i>
                                <input className="flex-1 max-h-9" placeholder="Placeholder" type="text" />
                            </div> */}
                            </div>
                        </div>


                        <div className="grid lg:grid-cols-3 gap-5" uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 50">

                            {
                                UserData.map((user, id) => {
                                    return (

                                        <div className="p-5 bg-white rounded-md shadow uk-scrollspy-inview uk-animation-fade" >

                                            <div className="flex items-center space-x-3 mb-4">
                                                <img src="../assets/images/avatars/avatar-1.jpg" alt="" className="w-12 rounded-full" />
                                                <div className="flex-1 min-w-0">
                                                    <h5 className="text-gray-600 font-semibold text-base"> {user.name} <img src={`/assets/images/${user.gender}.png`} width="15px" /> </h5>
                                                    {/* <div className="text-gray-600 text-sm"> info @mydomain.com </div> */}
                                                </div>
                                            </div>
                                            <div className="font-medium border-t pt-2">
                                                <div className="flex items-center py-2 space-x-3">
                                                    <div className="flex flex-1">
                                                        <ion-icon name="play-circle-outline" class="text-xl mr-3 md hydrated" role="img" aria-label="play circle outline"></ion-icon>
                                                        Learning Path
                                                    </div>
                                                </div>
                                                <div className="flex items-center py-2 space-x-3">
                                                    <div className="flex flex-1">
                                                        <div className="text-black">  {user.role}  </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex mt-3 space-x-2 text-sm">
                                                {/* <a href="#" className="bg-gray-100 flex-1 py-1.5 rounded text-center"> View</a> */}
                                                <Link onClick={(e) => this.selectUser(e, user)} className="bg-gray-100 flex-1 py-1.5 rounded text-center"> Profile</Link>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>

                        {/* <div className="flex justify-center mt-6">
                        <a href="#" className="bg-gray-100 border my-5 px-6 py-2 rounded-md shadow-sm"> Load more ..</a>
                    </div> */}


                    </div>
                </>
            );
        }
    }
}

export default Dashboard;