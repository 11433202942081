import React, { Component } from 'react';
import Experience from '../../Components/Profile/Experience';

class Profile extends Component {

    constructor(props) {
        super(props)
        this.state = {
            description: [""],

        }
    }
    
    
    onAddDes() {
        let { description } = this.state
        description.push("");
        this.setState({ description })
    }
    

    onChangeDescription = (event, index, keyName) => {
        let array = this.state[keyName];
        if (!isNaN(index)) {
            let value = event.target.value;
            array[index] = value;
            this.setState({ [keyName]: array })
        }
    }

    onSaveChanges = async () => {
        
    }

    render() {
        let { description } = this.state
        return (
            <>
                <div class="container">

                    <div className="breadcrumb-area py-0 md:mb-10 mb-6 justify-between sm:flex flex-column">
                        <h3 className="md:text-2xl text-lg font-semibold mb-2"> User Profile </h3>
                        <ul class="breadcrumb flex flex-row text-md">
                            <li className="text-md"><a href="#">Dashboard</a></li>
                            <li className="text-md active">User Profile</li>
                        </ul>
                    </div>
                    <div class="grid lg:grid-cols-3 mt-12 gap-8">
                        {/* <div>
                            <h3 class="text-xl mb-2 font-semibold"> Basic</h3>
                            <p> Lorem ipsum dolor sit amet nibh consectetuer adipiscing elit</p>
                        </div> */}
                        <div class="bg-white rounded-md lg:shadow-md shadow col-span-3">
                            <div class="grid grid-cols-2 gap-3 lg:p-6 p-4">
                                {/* <div class="col-span-2 flex py-2 space-x-5">
                                    <img src="../assets/images/image-placeholder.jpg" alt="" class="h-16 rounded-full w-16" />
                                    <div class="border font-medium px-3 py-1.5 rounded-md self-center shadow-sm text-center text-sm" uk-form-custom>
                                        <input type="file" style={{ opacity: 0 }} /> Change
                                    </div>
                                </div> */}
                                <div>
                                    <img src="../assets/images/avatars/placeholder.png" alt="" class="h-20 rounded-full w-20 mb-4" />
                                    <a href="#" class="border font-medium px-3 py-1.5 rounded-md self-center shadow-sm text-center text-sm">
                                        <input type="file" style={{ opacity: 0,position:"absolute",width:'5%' }} /> Change</a>
                                </div>
                                <div>
                                    <label for="first-name"> First name</label>
                                    <input type="text" placeholder="" id="first-name" class="shadow-none with-border" />
                                </div>
                                <div>
                                    <label for="last-name"> Last name</label>
                                    <input type="text" placeholder="" id="last-name" class="shadow-none with-border" />
                                </div>
                                <div class="">
                                    <label for="email"> Email</label>
                                    <input type="text" placeholder="" id="email" class="shadow-none with-border" />
                                </div>
                                <div>
                                    <label for="first-name"> Age</label>
                                    <input type="text" placeholder="" id="first-name" class="shadow-none with-border" />
                                </div>
                                <div>
                                    <label for="last-name"> Gender</label>
                                    <input type="text" placeholder="" id="last-name" class="shadow-none with-border" />
                                </div>
                                <div class="col-span-2">
                                    <label for="email"> Ethnicity</label>
                                    <input type="text" placeholder="" id="email" class="shadow-none with-border" />
                                </div>

                                <div class="col-span-2">
                                    <label for="about">Description</label>
                                    <textarea id="about" name="about" rows="3" class="with-border"></textarea>
                                </div>

                                <div class="col-span-2">
                                    <label for="email"> Skills</label>
                                    <input type="text" placeholder="" id="email" class="shadow-none with-border" />
                                </div>
                                
                                <Experience />
                                <div class="col-span-2">
                                    <label for="email"> Upload your CV</label>
                                    <input type="file" placeholder="" id="email" class="shadow-none" />
                                </div>

                                {/* <div>
                                    <button type="button" class="button bg-blue-700" onClick={() => { Experience.onAddForms(); }}>+</button>
                                </div> */}

                            </div>
                            <div class="bg-gray-10 p-6 pt-0 flex justify-end space-x-3">
                                <button class="p-2 px-4 rounded bg-gray-50 text-red-500"> Cancel </button>
                                <button type="button" class="button bg-blue-700"> Save </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Profile;