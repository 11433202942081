import React, { Component } from 'react';
import { Bounce } from "react-activity";
import "react-activity/dist/Bounce.css";
import { Redirect } from 'react-router-dom';
import CareerPath from '../../Components/Chart/CareerPath';
import CareerPath1 from '../../Components/Chart/CareerPath1';
import TrainingPathChart from '../../Components/Chart/TrainingPath';
class TrainingPath extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            model: false,
            user: null,
            redirect: false,
            one: {
                title: 'B.Sc. Computer Science',
                details: [
                    "This course is available, but you cannot enroll online. Please make an enquiry below, and someone will contact you. ",
                    "This qualification is designed for learners who want to gain knowledge of coding. The qualification may also support progression into further study in coding or other topics within the digital sector.",
                ],
                date: 'From 03 Nov 2021 to 27 Jul 2022 Wed 18:00-21:00',
                fee: '£465',
                link: 'https://www.webenrol.com/skillsandlearningace/?page=detail&courseCode=AT1923A',
            },
            TrainingList: {
                Step1: {
                    name: "Junior Java Developer",
                    className: "bg-blue-600",
                    icon: {
                        name: "checkmark-outline",
                        className: "bg-green-900",
                        title: "Natural progression – coherent"
                    },
                    startToEndYear: "Jan 2018 – Jan 2019",
                },
                Step2: {
                    name: "Junior Support Officer",
                    className: "bg-blue-600",
                    icon: {
                        name: "checkmark-outline",
                        className: "bg-green-900",
                        title: "Natural progression – coherent"
                    },
                    startToEndYear: "Feb 2019 - Jan 2020",
                },
                Step3: {
                    name: "Junior Devops Engineer",
                    className: "bg-blue-600",
                    icon: {
                        name: "warning-sharp",
                        className: "bg-yellow-600",
                        title: "Uncoherent progression - event"
                    },
                    startToEndYear: "Feb 2020 - Current"
                },
                Step4: {
                    name: "Full Stack Web & App Developer",
                    className: "bg-yellow-400",
                    icon: {
                        name: "heart-outline",
                        className: "bg-red-700",
                        title: "Desired next step - validated"
                    },
                    ModelDetails: {
                        title: 'B.Sc. Computer Science',
                        details: [
                            "This course is available, but you cannot enroll online. Please make an enquiry below, and someone will contact you. ",
                            "This qualification is designed for learners who want to gain knowledge of coding. The qualification may also support progression into further study in coding or other topics within the digital sector.",
                        ],
                        date: 'From 03 Nov 2021 to 27 Jul 2022 Wed 18:00-21:00',
                        fee: '£465',
                        link: 'https://www.webenrol.com/skillsandlearningace/?page=detail&courseCode=AT1923A',
                    }
                },
                Step5: {
                    name: "Senior Java Full Stack Dev",
                    className: "bg-yellow-400",
                    icon: {
                        name: "heart-outline",
                        className: "bg-red-700",
                        title: "Desired next step - validated"
                    },
                },
                Step6: {
                    name: "Scrum Manager",
                    className: "bg-yellow-400",
                    icon: {
                        name: "heart-dislike-sharp",
                        className: "bg-yellow-700",
                        title: "Potential next step – not validated"
                    },
                },
                Step7: {
                    name: "Scrum Manager",
                    className: "bg-yellow-400",
                    icon: {
                        name: "heart-dislike-sharp",
                        className: "bg-yellow-700",
                        title: "Potential next step – not validated"
                    },
                },
                Step8: {
                    name: "Scrum Manager",
                    className: "bg-yellow-400",
                    icon: {
                        name: "heart-dislike-sharp",
                        className: "bg-yellow-700",
                        title: "Potential next step – not validated"
                    },
                }
            },
        }
    }

    componentDidMount = async () => {
        // console.log("Hello There")
        await this.getUser();
    }

    getUser = async () => {

        let user = localStorage.getItem('user');
        user = JSON.parse(user);
        if (user) {
            // this.setState({ user, TrainingList: user.trainingPath, trainingChart: user.trainingChart });
            this.setState({ user, TrainingList: user.learningPath, trainingChart: "ta" });
            setTimeout(() => {
                this.setState({ loading: false })
            }, 5000)
        }
        else {
            this.setState({ loading: false, user: null, redirect: true })
        }
    }

    render() {
        return (
            <div className="container">
                {
                    this.state.redirect &&
                    <Redirect path="/" />
                }
                <div className="breadcrumb-area py-0 md:mb-10 mb-6 justify-between sm:flex flex-column">
                    <h3 className="md:text-2xl text-lg font-semibold mb-2"> My Training Path </h3>
                    <ul className="breadcrumb flex flex-row text-md">
                        <li className="text-md"><a href="demo#/dashboard">Dashboard</a></li>
                        <li className="text-md"><a href="demo#/my-profile">User Profile</a></li>
                        <li className="text-md active">My Training Path</li>
                    </ul>
                </div>
                {
                    this.state.loading ?
                        <div className="bg-white rounded-md lg:shadow-md shadow col-span-3 h-52 flex justify-center align-items-center flex-column">
                            <div className="">
                                <Bounce color="#727981" size={32} speed={1} animating={true} />
                            </div>
                            <div className="pt-6 text-2xl font-semibold text-black">
                                Build Training Path
                            </div>
                        </div>
                        :
                        <>
                            {
                                this.state.trainingChart == "ta" &&
                                <TrainingPathChart ChartData={this.state.TrainingList} />
                            }
                            {
                                this.state.trainingChart == "ca1" &&
                                <CareerPath ChartData={this.state.TrainingList} />
                            }
                            {
                                this.state.trainingChart == "ca2" &&
                                <CareerPath1 ChartData={this.state.TrainingList} />
                            }
                        </>
                }
            </div>
        );
    }
}

export default TrainingPath;