import { Component } from 'react';

class Functions extends Component {
    onChange(event, object, validate = '') {
        let nam = event.target.name;
        let val = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        if(validate === 'String'){
            val = val.replace(/[^a-zA-Z\s]/g, '');
        }
        if(validate === 'Number'){
            val = val.replace(/[^0-9]/g, '');
        }
        object.setState({ [nam]: val });
        // console.log(val)
    }    
}


const AllFunction = new Functions();
export default AllFunction;