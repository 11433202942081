import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Footer from '../../Common/Footer';
import Header from '../../Common/Header';
import AllFunctions from '../../Utilities/Functions'
class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            userName: "",
            email: "",
            password: "",
            gender: "",
            phone: "",

        }
        this.submit_Handler = this.submit_Handler.bind(this);
    }
    componentDidMount() { }

    


    submit_Handler(event) {
        try {
            console.log("Submit Event is :-", event);

            const firstName = this.state.FirstName;
            const LastName = this.state.LastName;
            const Username = this.state.Username;
            const EmailAddress = this.state.EmailAddress;
            const Password = this.state.Password;
            const Gender = this.state.Gender;
            const Phone = this.state.Phone;

            console.log("firstName is :-", firstName + '\n' + "LastName is :-", LastName + '\n' + "Username is :-", Username + '\n' + "EmailAddress is :-", EmailAddress + '\n' + "Password is :-", Password
                + '\n' + "Gender is :-", Gender + '\n' + "Phone is :-", Phone);
            if (firstName && firstName.trim() !== '' && LastName && LastName && Username && EmailAddress && Password && Gender && Phone) {
                return (
                    alert("OK Continue..."),
                    event.preventDefault(),
                    window.location = './UserView'
                );
            } else { alert("Please Fill Data Carefully"); }
            console.log("---------- Submit Handler Function End -----------");
        }
        catch (e) {
            console.log("Error in Handle Submit :-", e);
        }
    }

    render() {
        return (
            <div id="wrapper" class="flex flex-col justify-between h-screen">
            
                <Header />
                <div className="lg:p-12 max-w-xl lg:my-0 my-12 mx-auto p-6 space-y-">
                    <form className="lg:p-10 p-6 space-y-3 relative bg-white shadow-xl rounded-md">
                        <h1 className="lg:text-2xl text-xl font-semibold mb-6"> Register </h1>

                        <div className="grid lg:grid-cols-2 gap-3">
                            <div>
                                <label className="mb-0" htmlFor="first-name"> First Name </label>
                                <input type="text" placeholder="Your Name" id="Firstname" name="firstName" className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full" value={this.state.Firstname} onChange={(event)=>{AllFunctions.onChange(event,this)}} />
                            </div>
                            <div>
                                <label className="mb-0" htmlFor="last-name"> Last  Name </label>
                                <input type="text" placeholder="Last  Name" id="LastName" name="lastName" className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full" value={this.state.LastName} onChange={(event)=>{AllFunctions.onChange(event,this)}} required />
                            </div>
                        </div>
                        <div>
                            <label className="mb-0" htmlFor="username"> Username </label>
                            <input type="text" placeholder="Username" id="Username" name="userName" className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full" value={this.state.Username} onChange={(event)=>{AllFunctions.onChange(event,this)}} required />
                        </div>
                        <div>
                            <label className="mb-0" htmlFor="email"> Email </label>
                            <input type="email" placeholder="Info@example.com" id="EmailAddress" name="email" className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full" value={this.state.email} onChange={(event)=>{AllFunctions.onChange(event,this)}} required />
                        </div>
                        <div>
                            <label className="mb-0" htmlFor="password"> Password </label>
                            <input type="password" placeholder="******" id="Password" name="password" className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full" value={this.state.Password} onChange={(event)=>{AllFunctions.onChange(event,this)}} required />
                        </div>
                        <div className="grid lg:grid-cols-2 gap-3">
                            <div>
                                <label className="mb-0"> Gender </label>
                                <select className="selectpicker mt-2" value={this.state.Gender} onChange={(event)=>{AllFunctions.onChange(event,this)}} required>
                                    <option>Male</option>
                                    <option>Female</option>
                                </select>

                            </div>
                            <div>
                                <label className="mb-0"> Phone: optional  </label>
                                <input type="text" placeholder="+543 5445 0543" id="Phone" name="phone" className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full" value={this.state.Phone} onChange={(event)=>{AllFunctions.onChange(event,this)}} required />
                            </div>
                        </div>

                        <div className="checkbox">
                            <input type="checkbox" id="chekcbox1" defaultChecked="" onChange={(event)=>{AllFunctions.onChange(event,this)}} />
                            <label htmlFor="chekcbox1"><span className="checkbox-icon"></span> I agree to the <a href="pages-terms.html" target="_blank" className="uk-text-bold uk-text-small uk-link-reset"> Terms and Conditions </a>
                            </label>
                        </div>

                        <div>
                            <button type="submit" className="bg-purple-500 font-semibold p-2 mt-5 rounded-md text-center text-white w-full" onClick={(event) => this.submit_Handler(event)} >
                                Sign Up</button>
                        </div>
                    </form>


                </div>
                {/* <div className="lg:mb-5 py-3 uk-link-reset">
                    <div className="flex flex-col items-center justify-between lg:flex-row max-w-6xl mx-auto lg:space-y-0 space-y-3">
                        <div className="flex space-x-2 text-gray-700 uppercase">
                            <a href="#"> About</a>
                            <a href="#"> Help</a>
                            <a href="#"> Terms</a>
                            <a href="#"> Privacy</a>
                        </div>
                        <p className="capitalize"> © copyright 2021 by Virtual Skills Platform</p>
                    </div>
                </div> */}
                <Footer />

            </div>


        );
    }
}

export default withRouter(Signup);