import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { appendScript } from '../../Utilities/appendScript';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

class UserProfileMenuCard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            description: [""],
            loading: true,
            user: null,
            redirect: false
        }
    }

    componentDidMount = async () => {
        await this.getUser();

        // await appendScript('/assets/js/bootstrap-select.min.js');
        await appendScript('/assets/js/load-select.js');
    }

    getUser = async () => {

        let user = localStorage.getItem('user');
        user = JSON.parse(user);
        if (user) {
            this.setState({ loading: false, user });
            // console.log("found user: ", user)
        }
        else {
            this.setState({ loading: false, user: null, redirect: true })
        }
    }

    onAddDes() {
        let { description } = this.state
        description.push("");
        this.setState({ description })
    }

    onChangeDescription = (event, index, keyName) => {
        let array = this.state[keyName];
        if (!isNaN(index)) {
            let value = event.target.value;
            array[index] = value;
            this.setState({ [keyName]: array })
        }
    }

    render() {
        let { user } = this.state

        if (this.state.loading) {
            return (
                <>
                    loading...
                </>
            )
        }
        else {

            return (
                <>
                    <div className="container">
                        <div className="breadcrumb-area py-0 md:mb-10 mb-6 justify-between sm:flex flex-column">
                            <h3 className="md:text-2xl text-lg font-semibold mb-2"> My Dashboard </h3>
                            {/* <ul class="breadcrumb flex flex-row text-md">
                                <li className="text-md"><a href="#">Dashboard</a></li>
                                <li className="text-md"><a href="#">User Profile</a></li>
                                <li className="text-md active">My Panel</li>
                            </ul> */}
                        </div>
                        <div className="grid lg:grid-cols-3 gap-2">

                            <div className="bg-white rounded-md lg:shadow-md shadow col-span-3">
                                <div className="lg:p-6 p-4">
                                    <div className="md:p-7 p-5  ">
                                        <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-2 -m-3">
                                            <Link to={"/my-profile"} className="">
                                                <div className="shadow-md hover:bg-gray-100 flex flex-column items-start px-3 py-2 rounded-lg space-x-3 skill-hover">
                                                    <div className="text-3xl text-white from-red-600 to-red-400 bg-gradient-to-tl p-2 rounded-md md hydrated" role="img" >
                                                        <span className=" icon-material-outline-dashboard">

                                                        </span>
                                                    </div>
                                                    <div className="py-3 px-3">
                                                        <h3 className="font-semibold text-lg">About me</h3>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="/my-training-path" className="">
                                                <div className="shadow-md hover:bg-gray-100 flex flex-column items-start px-3 py-2 rounded-lg space-x-3 skill-hover">
                                                    <div name="briefcase" className="text-3xl text-white from-blue-600 to-blue-400 bg-gradient-to-tl p-2 rounded-md md hydrated" role="img"  >
                                                        <span className="icon-material-outline-folder-shared">

                                                        </span>
                                                    </div>
                                                    <div className="py-3 px-3">
                                                        <h3 className="font-semibold text-lg"> My Training Path</h3>
                                                        {/* <div className="flex space-x-2 items-center text-sm pt-0.5">
                                                    <div> 16 Courses </div>
                                                    <div>·</div>
                                                    <div> 523 Students</div>
                                                </div> */}
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="/#" onClick={ (event) => event.preventDefault() } className="">
                                                <div className="shadow-md hover:bg-gray-100 flex flex-column items-start px-3 py-2 rounded-lg space-x-3 skill-hover disabled-hover">
                                                    <div name="color-wand" className="text-3xl text-white from-purple-600 to-purple-400 bg-gradient-to-tl p-2 rounded-md md hydrated" role="img" >
                                                        <span className=" icon-material-outline-add-photo-alternate">

                                                        </span>
                                                    </div>
                                                    <div className="py-3 px-3">
                                                        <h3 className="font-semibold text-lg">Ask an Advisor</h3>
                                                        {/* <div className="flex space-x-2 items-center text-sm pt-0.5">
                                                    <div> 23 Courses </div>
                                                    <div>·</div>
                                                    <div> 356  Students</div>
                                                </div> */}
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="/my-career-path" className="">
                                                <div className="shadow-md hover:bg-gray-100 flex flex-column items-start px-3 py-2 rounded-lg space-x-3 skill-hover">
                                                    <div name="shield-checkmark" className="text-3xl text-white from-yellow-600 to-yellow-400 bg-gradient-to-tl p-2 rounded-md md hydrated" role="img" >
                                                        <span className="icon-feather-check-square">

                                                        </span>
                                                    </div>
                                                    <div className="py-3 px-3">
                                                        <h3 className="font-semibold text-lg"> My Career Path</h3>
                                                        {/* <div className="flex space-x-2 items-center text-sm pt-0.5">
                                                    <div> 12 Courses </div>
                                                    <div>·</div>
                                                    <div> 256 Students</div>
                                                </div> */}
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="#" onClick={ (event) => event.preventDefault() } className="">
                                                <div className="shadow-md hover:bg-gray-100 flex flex-column items-start px-3 py-2 rounded-lg space-x-3 skill-hover disabled-hover">
                                                    {/* <div name="leaf" className="text-3xl text-white from-green-600 to-green-400 bg-gradient-to-tl p-2 rounded-md md hydrated" role="img" > */}
                                                    <span className="text-3xl text-white from-green-600 to-green-400 bg-gradient-to-tl p-2 rounded-md md hydrated  icon-feather-search">

                                                    </span>
                                                    {/* </div> */}
                                                    <div className="py-3 px-3">
                                                        <h3 className="font-semibold text-lg"> Training search</h3>
                                                        {/* <div className="flex space-x-2 items-center text-sm pt-0.5">
                                                    <div> 34 Courses </div>
                                                    <div>·</div>
                                                    <div> 420 Students</div>
                                                </div> */}
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="/online-help" className="">
                                                <div className="shadow-md hover:bg-gray-100 flex flex-column items-start px-3 py-2 rounded-lg space-x-3 skill-hover">
                                                    {/* <div name="icon-material-outline-attach-file" className="text-3xl text-white from-pink-600 to-pink-400 bg-gradient-to-tl p-2 rounded-md md hydrated" role="img" ></div> */}
                                                    <span className=" text-3xl text-white from-pink-600 to-pink-400 bg-gradient-to-tl p-2 rounded-md md hydrated icon-material-outline-question-answer" role="img" >

                                                    </span>
                                                    <div className="py-3 px-3">
                                                        <h3 className="font-semibold text-lg text-center"> Online help</h3>
                                                        {/* <div className="flex space-x-2 items-center justify-center text-sm pt-0.5">
                                                            <input type="file" id="cv" style={{ height: 'auto', padding: 1 }} className="shadow-none" />
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="#" onClick={ (event) => event.preventDefault() } className="">
                                                <div className="shadow-md hover:bg-gray-100 flex flex-column items-start px-3 py-2 rounded-lg space-x-3 skill-hover disabled-hover">
                                                    {/* <div name="icon-material-outline-attach-file" className="text-3xl text-white from-pink-600 to-pink-400 bg-gradient-to-tl p-2 rounded-md md hydrated" role="img" ></div> */}
                                                    <span className=" text-3xl text-white from-pink-600 to-pink-400 bg-gradient-to-tl p-2 rounded-md md hydrated  icon-material-outline-find-in-page" role="img" >

                                                    </span>
                                                    <div className="py-3 px-3">
                                                        <h3 className="font-semibold text-lg text-center"> My Job searches</h3>
                                                        {/* <div className="flex space-x-2 items-center justify-center text-sm pt-0.5">
                                                            <input type="file" id="cv" style={{ height: 'auto', padding: 1 }} className="shadow-none" />
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="#" onClick={ (event) => event.preventDefault() } className="">
                                                <div className="shadow-md hover:bg-gray-100 flex flex-column items-start px-3 py-2 rounded-lg space-x-3 skill-hover disabled-hover">
                                                    {/* <div name="icon-material-outline-attach-file" className="text-3xl text-white from-pink-600 to-pink-400 bg-gradient-to-tl p-2 rounded-md md hydrated" role="img" ></div> */}
                                                    <span className=" text-3xl text-white from-blue-600 to-blue-400 bg-gradient-to-tl p-2 rounded-md md hydrated icon-feather-archive" role="img" >

                                                    </span>
                                                    <div className="py-3 px-3">
                                                        <h3 className="font-semibold text-lg text-center"> Job Opportunities</h3>
                                                        {/* <div className="flex space-x-2 items-center justify-center text-sm pt-0.5">
                                                            <input type="file" id="cv" style={{ height: 'auto', padding: 1 }} className="shadow-none" />
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div style={{}}>

                                        {this.state.counter ?
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            
                                            <CountdownCircleTimer
                                                isPlaying
                                                duration={10}
                                                colors={[
                                                    ['#004777', 0.4],
                                                    ['#F7B801', 0.4],
                                                    ['#A30000', 0.2],
                                                ]}
                                                onComplete={() => { this.setState({ counter:false }); alert("Training Path Updated.") }}
                                            >
                                                {({ remainingTime }) =>
                                                    <div className="time-wrapper text-center">
                                                        <div className="time" style={{ fontSize: '2rem' }}>{remainingTime}</div>
                                                        <div>seconds</div>
                                                    </div>
                                                }
                                            </CountdownCircleTimer>
                                            </div>
                                            :
                                            <div className="row mt-10 ">
                                                <div className="col-md-12 text-center" style={{ textAlign: 'center', }}>
                                                    <a onClick={() => this.setState({ counter: true }) }  type="button" className="button bg-red-500" style={{ backgroundColor: 'rgba(239, 68, 68, 500)' }}> Update Training Path
                                                    </a>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    }
}

export default UserProfileMenuCard;