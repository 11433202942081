import React, { Component } from 'react';

class Dashboard extends Component {
    render() {
        return (
            <>
                <div class="container">
                    <div class="md:space-y-8 space-y-4">
                        <div class="-mb-3 space-y-2">
                            <h6 class="font-medium"> Welcome John</h6>
                            <h1 class="font-semibold md:text-3xl text-xl"> Dashboard</h1>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Dashboard;