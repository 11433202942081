/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    user: {
        interested: [
            { title: 'Upskill to promote', value: 'upskill_promote' },
            { title: 'Upskill for a new job', value: 'upskill_new_job' },
            { title: 'Career boost', value: 'career_booster' },
        ]
    },
    url: "http://vsp.easydevices.in:6787/",
    // url: "http://localhost:4000/api/",
    skill_questions: [
        {
            "question": "I have access to computers and other media devices",
            "type": "Skills",
            "options": [
                {
                    "title": "yes",
                    "value": "yes"
                },
                {
                    "title": "unsure ",
                    "value": "unsure"
                },
                {
                    "title": "no",
                    "value": "no"
                }
            ]
        },
        {
            "question": "I do have a good internet connection",
            "type": "Skills",
            "options": [
                {
                    "title": "yes",
                    "value": "yes"
                },
                {
                    "title": "unsure ",
                    "value": "unsure"
                },
                {
                    "title": "no",
                    "value": "no"
                }
            ]
        },
        {
            "question": "I know how to use the Internet and search for specific information",
            "type": "Skills",
            "options": [
                {
                    "title": "yes",
                    "value": "yes"
                },
                {
                    "title": "unsure ",
                    "value": "unsure"
                },
                {
                    "title": "no",
                    "value": "no"
                }
            ]
        },
        {
            "question": "If I have connection or computer problems I can be easily helped",
            "type": "Skills",
            "options": [
                {
                    "title": "yes",
                    "value": "yes"
                },
                {
                    "title": "unsure ",
                    "value": "unsure"
                },
                {
                    "title": "no",
                    "value": "no"
                }
            ]
        },
        {
            "question": "I have studied before through online learning or adult learning",
            "type": "Skills",
            "options": [
                {
                    "title": "yes",
                    "value": "yes"
                },
                {
                    "title": "no",
                    "value": "no"
                }
            ]
        },
        {
            "question": "Actually, I have recently online learned, including undertaking an online short course",
            "type": "Skills",
            "options": [
                {
                    "title": "yes",
                    "value": "yes"
                },
                {
                    "title": "no",
                    "value": "no"
                }
            ]
        },
        {
            "question": "I consider myself a capable reader, I read and comprehend in a fast way",
            "type": "Skills",
            "options": [
                {
                    "title": "yes",
                    "value": "yes"
                },
                {
                    "title": "unsure ",
                    "value": "unsure"
                },
                {
                    "title": "no",
                    "value": "no"
                }
            ]
        },
        {
            "question": "My ability to use a calculator and perform basic mathematical and logical operations is OK",
            "type": "Skills",
            "options": [
                {
                    "title": "yes",
                    "value": "yes"
                },
                {
                    "title": "unsure ",
                    "value": "unsure"
                }
            ]
        }
    ],
    answers_questions: [
        {
            "question": "My current employment status is",
            "type": "Answer",
            "options": [
                {
                    "value": "full-time",
                    "title": "full time"
                },
                {
                    "value": "part-time",
                    "title": "part time"
                },
                {
                    "value": "not-in-paid-work",
                    "title": "not in paid work"
                },
                {
                    "value": "full-time-caregiver",
                    "title": "full time caregiver"
                }
            ]
        },
        {
            "question": "My family and social environment supportive of my desire to undertake further study",
            "type": "Answer",
            "options": [
                {
                    "title": "yes",
                    "value": "yes"
                },
                {
                    "title": "unsure",
                    "value": "unsure"
                },
                {
                    "title": "no",
                    "value": "no"
                }
            ]
        },
        {
            "question": "I think I can get hold of enough ‘free time’ in your life to commit to further studies",
            "type": "Answer",
            "options": [
                {
                    "title": "yes",
                    "value": "yes"
                },
                {
                    "title": "unsure",
                    "value": "unsure"
                },
                {
                    "title": "no",
                    "value": "no"
                }
            ]
        },
        {
            "question": "I am good at working to deadlines and meeting them",
            "type": "Answer",
            "options": [
                {
                    "title": "yes",
                    "value": "yes"
                },
                {
                    "title": "unsure",
                    "value": "unsure"
                },
                {
                    "title": "no",
                    "value": "no"
                }
            ]
        },
        {
            "question": "I tend to persevere on tasks even when the work gets difficult",
            "type": "Answer",
            "options": [
                {
                    "title": "yes",
                    "value": "yes"
                },
                {
                    "title": "unsure",
                    "value": "unsure"
                },
                {
                    "title": "no",
                    "value": "no"
                }
            ]
        },
        {
            "question": "I like sharing tasks and being involved in collaborative assignments and projects",
            "type": "Answer",
            "options": [
                {
                    "title": "yes",
                    "value": "yes"
                },
                {
                    "title": "unsure",
                    "value": "unsure"
                },
                {
                    "title": "no",
                    "value": "no"
                }
            ]
        },
        {
            "question": "I feel anxious when doing tests and exams",
            "type": "Answer",
            "options": [
                {
                    "title": "yes",
                    "value": "yes"
                },
                {
                    "title": "unsure",
                    "value": "unsure"
                },
                {
                    "title": "no",
                    "value": "no"
                }
            ]
        }
    ]
}
