import React, { Component } from 'react';

let formObject = {
    title: {
        label: 'Title',
        value: '',
        isRequired: true,
        error: null,
        type: 'text',
        placeholder: '',
        keyName: 'title'
    },
    location: {
        label: 'Location',
        value: '',
        isRequired: false,
        error: null,
        type: 'text',
        placeholder: '',
        keyName: 'location'
    },
    fromDate: {
        label: 'From Date',
        value: '',
        isRequired: true,
        error: null,
        type: 'text',
        placeholder: '',
        keyName: 'fromDate'
    },
    toDate: {
        label: 'To Date',
        value: '',
        isRequired: true,
        error: null,
        type: 'text',
        placeholder: '',
        keyName: 'toDate'
    },
    description: {
        label: 'Description',
        value: '',
        isRequired: true,
        error: null,
        type: 'text',
        placeholder: '',
        keyName: 'description'
    },

}
class Experience extends Component {
    constructor(props) {
        super(props)
        this.state = {
            forms: [formObject]
        }
    }

    componentDidMount() {
        // this.onAddForms()
    }

    onAddForms() {
        let { forms } = this.state
        forms.push(formObject);
        this.setState({ forms })
    }

    onChangeFormField = (event, index) => {
        let { forms } = this.state;
        if (!isNaN(index)) {
            let name = event.target.name;
            let value = event.target.value;
            let formOBJ = forms[index];
            formOBJ[name]["value"] = value;
            forms[index] = formOBJ;
            // console.log(formOBJ[name], "FormOBJ")
            // console.log(forms[index]);
            this.setState({ forms })
        }
      
    }

    onRemoveForms = (index) => {
        let { forms } = this.state;
        forms.splice(index, 1);
        this.setState({ forms })
    }

    render() {
        const { forms } = this.state;
        return (
            <>
                <div className="grid grid-cols-2 gap-3 lg:p-6 p-4">
                    <div className="text-black font-semibold text-base pt-2">Add Experience</div>
                    <div className="text-right">
                        {/* <button type="button" className="bg-blue-700 p-2 px-4 rounded text-white" onClick={() => { this.onAddForms(); }}>+</button> */}
                        <button type="button" className="bg-blue-700 p-2 px-4 rounded text-white ml-2" onClick={() => { this.onAddForms(); }}><ion-icon name="add" class="md hydrated" role="img" aria-label="key outline" style={{ paddingTop: 4 }}></ion-icon></button>
                    </div>
                </div>


                {/* <div className="col-span-2">
                        <label htmlFor="email"> Title <span style={{ color: 'red' }}>*</span></label>
                        <input type="text" placeholder="" id="email" className="shadow-none with-border" />
                    </div>
                    <div className="col-span-2">
                        <label htmlFor="first-name"> Location <span style={{ color: 'red' }}></span></label>
                        <input type="text" placeholder="" id="first-name" className="shadow-none with-border" />
                    </div>
                    <div>
                        <label htmlFor="last-name"> From Date</label>
                        <input type="text" placeholder="" id="last-name" className="shadow-none with-border" />
                    </div>
                    <div>
                        <label htmlFor="email"> To Date</label>
                        <input type="text" placeholder="" id="email" className="shadow-none with-border" />
                    </div>

                    <div className="col-span-2">
                        <label htmlFor="about">Description <span style={{ color: 'red' }}>*</span></label>
                        <textarea id="about" name="about" rows="3" className="with-border"></textarea>
                    </div> */}

                {
                    (forms && forms.length > 0) ?
                        forms.map((field, index) => {
                            return (
                                <div className="grid grid-cols-2 gap-3 lg:px-6 pb-6">
                                    {
                                        Object.entries(field).map((fieldName, fIndex) => {
                                            let key = fieldName[0]
                                            let obj = fieldName[1]
                                            // console.log(key,"KEY")
                                            return (
                                                <>
                                                    <div className={(key == "toDate" || key == "fromDate") ? "" : "col-span-2"} key={fIndex}>
                                                        <label for="first-name">{obj.label}</label>
                                                        <input name={key} type={obj.type} placeholder={obj.placeholder} id="first-name" className="shadow-none with-border" value={obj.value} onChange={(event) => { this.onChangeFormField(event, index) }} />
                                                    </div>
                                                    {
                                                        index > 0 && Object.entries(field).length - 1 == fIndex ?
                                                            <>
                                                                <div></div>
                                                                <div className="text-right">
                                                                    <button type="button" className="p-2 px-4 rounded bg-red-200 text-red-700" onClick={() => { this.onRemoveForms(index); }}>-</button>
                                                                </div></>
                                                            : ''
                                                    }
                                                </>
                                            );
                                        })
                                    }
                                </div>
                            )
                        })
                        :
                        <div>no Data</div>
                }
            </>
        )

        // return(
        //     <></>
        // )
    }
}

export default Experience;