import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Sidebar extends Component {
    render() {
        return (
            <>
                {/* <!-- sidebar --> */}
                <div class="sidebar">
                    <div class="sidebar_inner justify-content-between" data-simplebar>

                        {/* <ul class="side-colored">
                            <li class="active"><a href="explore.html">
                                <ion-icon name="compass" class="bg-gradient-to-br from-purple-300 p-1 rounded-md side-icon text-opacity-80 text-white to-blue-500">
                                </ion-icon>
                                <span> Explore</span>
                            </a>
                            </li>
                            <li><a href="courses.html">
                                <ion-icon name="play-circle" class="bg-gradient-to-br from-yellow-300 p-1 rounded-md side-icon text-opacity-80 text-white to-red-500">
                                </ion-icon>
                                <span> Courses</span>
                            </a>
                            </li>
                            <li><a href="categories.html">
                                <ion-icon name="albums" class="bg-gradient-to-br from-green-300 p-1 rounded-md side-icon text-opacity-80 text-white to-green-500">
                                </ion-icon>
                                <span> Categories </span>
                            </a>
                            </li>
                            <li><a href="episodes.html">
                                <ion-icon name="film" class="bg-gradient-to-br from-pink-300 p-1 rounded-md side-icon text-opacity-80 text-white to-red-500">
                                </ion-icon>
                                <span> Episodes </span>
                            </a>
                            </li>
                            <li><a href="books.html">
                                <ion-icon name="book" class="bg-gradient-to-br from-yellow-300 p-1 rounded-md side-icon text-opacity-80 text-white to-red-500">
                                </ion-icon>
                                <span> Books </span>
                            </a>
                            </li>
                            <li><a href="blogs.html">
                                <ion-icon name="newspaper" class="bg-gradient-to-br from-purple-300 p-1 rounded-md side-icon text-opacity-80 text-white to-blue-500">
                                </ion-icon>
                                <span> Articles</span>
                            </a>
                            </li>
                        </ul> */}

                        <ul class="side_links" data-sub-title="Pages">
                            <li><Link to={'/dashboard'}> <ion-icon name="home" class="side-icon"></ion-icon> Dashboard </Link></li>
                            <li><Link to={"/my-profile"}> <ion-icon name="person" class="side-icon"></ion-icon> My Profile </Link></li>
                            <li><Link to={'/my-career-path'}> <ion-icon name="card-outline" class="side-icon"></ion-icon> My Career Path </Link></li>
                            <li><Link to={'/my-training-path'}> <ion-icon name="card-outline" class="side-icon"></ion-icon> My Training Path </Link></li>
                            <li><Link to={'/'} > <ion-icon name="card-outline" class="side-icon"></ion-icon> My Job Searches </Link></li>
                            <li><Link to={'/'} > <ion-icon name="card-outline" class="side-icon"></ion-icon> New Job Opportunities </Link></li>
                            <li><Link to={'/take-survey'} > <ion-icon name="card-outline" class="side-icon"></ion-icon> Take Survey </Link></li>

                            {/* <li><a href="page-help.html"> <ion-icon name="information-circle-outline" class="side-icon"></ion-icon> Help </a></li>
                            <li><a href="page-faq.html"> <ion-icon name="albums-outline" class="side-icon"></ion-icon> Faq </a></li>
                            <li><a href="#"> <ion-icon name="albums-outline" class="side-icon"></ion-icon> Forum  <span class="soon">soon</span> </a></li>
                            <li><a href="page-term.html"> <ion-icon name="document-outline" class="side-icon"></ion-icon> Term </a></li>
                            <li><a href="page-privacy.html"> <ion-icon name="document-text-outline" class="side-icon"></ion-icon> Privacy </a></li>
                            <li><a href="page-setting.html"> <ion-icon name="settings-outline" class="side-icon"></ion-icon> Setting </a></li> */}
                            {/* <li><a href="#"> Development  </a>
                                <ul>
                                    <li><a href="development-elements.html"> Elements  </a></li>
                                    <li><a href="development-components.html"> Compounents </a></li>
                                    <li><a href="development-plugins.html"> Plugins </a></li>
                                    <li><a href="development-icons.html"> Icons </a></li>
                                </ul>
                            </li>
                            <li><a href="#"> Authentication  </a>
                                <ul>
                                    <li><a href="form-login.html">form login </a></li>
                                    <li><a href="form-register.html">form register</a></li>
                                </ul>
                            </li> */}
                        </ul>

                        {/* <div class="side_foot_links">
                            <a href="#">About</a>
                            <a href="#">Blog </a>
                            <a href="#">Careers</a>
                            <a href="#">Support</a>
                            <a href="#">Contact Us </a>
                            <a href="#">Developer</a>
                            <a href="#">Terms of service</a>
                        </div> */}
                        <div className="d-flex ">
                            <img className="w-14 p-0 bg-white shadow rounded-sm flex items-center space-x-3" src={process.env.PUBLIC_URL + "/assets/images/Footer.png"} />
                        </div>
                    </div>

                    <div class="side_overly" uk-toggle="target: #wrapper ; cls: is-collapse is-active"></div>
                </div>
            </>
        );
    }
}

export default Sidebar;