import React, { Component } from 'react';
import Routes from './Routes/routes';
import { Route, BrowserRouter as Router, Redirect } from 'react-router-dom';
import Login from './Pages/User/Login';
import Signup from './Pages/User/Signup';
import adminRoutes from './Routes/adminRoutes';
import userRoutes from './Routes/userRoutes';
import demoRoutes from './Routes/demoRoutes';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLogin: true,
      loading: true,
    }
  }

  async componentDidMount() {
    let user = await localStorage.getItem('user');
    user = await JSON.parse(user)
    if (user) {
      await this.setState({ isLogin: true })
    }
  }
  render() {
    return (
      <Router forceRefresh={true}>
        <Route path="/" name='App' exact component={Routes}></Route>
        <Route path="/login" component={Login}></Route>
        <Route path="/sign-up" component={Signup}></Route>
        <Route path="/admin" name='AdminApp' exact component={adminRoutes}></Route>
        <Route path="/user" name='UserApp' exact component={userRoutes}></Route>
        <Route path="/demo" name='UserApp' exact component={demoRoutes}></Route>
      </Router>
    );

  }

}

export default App;
