import React, { Component } from 'react';
import DynamicSelection from '../Components/DynamicSelection';
import Constants from '../Utilities/Constants';

class MyQuestionAnswer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            QuestionAnswer: Constants.answers_questions,
            loading: true,
            user: null,
            redirect: false
        }
    }

    componentDidMount() {
        this.getUser();
    }

    getUser = async () => {

        let user = localStorage.getItem('user');
        user = JSON.parse(user);
        if (user) {
            this.setState({ loading: false, user: user });
            console.log("found user: ", user)
        }
        else {
            this.setState({ loading: false, user: null, redirect: true })
        }
    }

    onSetOptionValue = (key, value) => {
        console.log("DATA ", key, value)
        let { QuestionAnswer, user } = this.state;
        QuestionAnswer[key] = { ...QuestionAnswer[key], answer: value };
        user.answers[key].answer.value = value;
        this.setState({ QuestionAnswer, user });
    }


    onSaveUser = async () => {

        let { user, error, SearchText, degree } = this.state;
        let userID = user._id
        this.setState({ saving: true });

        fetch(Constants.url + `users/${userID}`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(user)
            })
            .then((response) => response.json())
            .then((responseData) => {
                console.log(responseData)
                if (responseData.status) {
                    localStorage.setItem('user', JSON.stringify(user));
                    alert("User Details Successfully Updated.")
                    this.setState({ saving: false });

                } else {
                    alert("Something went wrong, Please try again after sometime.");
                    this.setState({ saving: false })
                }
            })
            .catch(err => {
                this.setState({ saving: false })
                alert("Something went wrong, Please try again after sometime.");
                console.log(err);
            });

    }


    render() {
        let { QuestionAnswer, user } = this.state;
        QuestionAnswer = QuestionAnswer.filter(question => question.type === "Answer")
        console.log(QuestionAnswer.length)
        if (this.state.loading) {
            return (
                <>
                    loading...
                </>
            )
        }
        else {


            return (
                <>
                    <div className="container">

                        <div className="breadcrumb-area py-0 md:mb-10 mb-6 justify-between sm:flex flex-column">
                            <h3 className="md:text-2xl text-lg font-semibold mb-2"> Other Answer </h3>
                            <ul class="breadcrumb flex flex-row text-md">
                                <li className="text-md"><a href="demo#/dashboard">Dashboard</a></li>
                                <li className="text-md"><a href="demo#/my-profile">User Profile</a></li>
                                <li className="text-md active">Other Answer</li>
                            </ul>
                        </div>
                        <div className="grid lg:grid-cols-3 mt-12 gap-8">
                            <div className="bg-white rounded-md lg:shadow-md shadow col-span-3">
                                <div className="grid gap-3 lg:p-6 p-4">
                                    {
                                        QuestionAnswer && QuestionAnswer.length > 0 ?
                                            QuestionAnswer.map((option, index) => {
                                                return (
                                                    <DynamicSelection
                                                        schema={{
                                                            label: "title",
                                                            value: "value",
                                                        }}
                                                        key={index}
                                                        labelName={option.question}
                                                        isInputHide={true}
                                                        keyName={index}
                                                        Options={option.options || []}
                                                        // value={option?.answer?.value || ""}
                                                        value={user.answers[index]?.answer?.value || ""}
                                                        onOptionsSelect={(name, value) => {console.log("name: ", name, value, index); this.onSetOptionValue(index, value.value) }}
                                                        onChange={(event) => { }}
                                                    />
                                                )
                                            })
                                            :
                                            <div className="grid grid-cols-1 text-gray-500 font-semibold px-2">No Options Available</div>
                                    }
                                </div>
                            </div>
                        </div>
                        <br />
                        <div>
                            <button type="button" className="button bg-blue-700" onClick={() => { this.onSaveUser() }}>Save</button>
                        </div>
                    </div>
                </>
            );
        }
    }
}

export default MyQuestionAnswer;