import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Constants from '../../Utilities/Constants';
import { appendScript } from '../../Utilities/appendScript';
import StarRatings from 'react-star-ratings';


class TakeSurvey extends Component {

    constructor(props) {
        super(props)
        this.state = {
            description: [""],
            loading: true,
            user: null,
            redirect: false,
            one: 0,
            two: 0,
            three: 0,
            four: 0,
            five: 0,
            six: 0,
            seven: "",
            eight: "",
            nine: "",
            ten: "",
        }
    }
    componentDidMount = async () => {
        this.getUser();

        await appendScript('/assets/js/bootstrap-select.min.js');
    }

    getUser = async () => {

        let user = localStorage.getItem('user');
        user = JSON.parse(user);
        if (user) {
            this.setState({ loading: false, user: user });
            console.log("found user: ", user)
        }
        else {
            this.setState({ loading: false, user: null, redirect: true })
        }
    }

    onAddDes() {
        let { description } = this.state
        description.push("");
        this.setState({ description })
    }

    onChangeDescription = (event, index, keyName) => {
        let array = this.state[keyName];
        if (!isNaN(index)) {
            let value = event.target.value;
            array[index] = value;
            this.setState({ [keyName]: array })
        }
    }

    changeRating = (newRating, name) => {
        console.log(newRating, name);
        this.setState({
            [name]: newRating
        });
    }


    checkValidation = async () => {
        const { email, password } = this.state;
        try {

            let error = {};

            if (!password || password.trim() == '') {
                error.password = 'Please Enter Your First Name';
            } else if (password && password.length > 8) {
                error.password = 'Password must be 8 Character';
            }

            if (!email || email.trim() === '') {
                error.email = 'Please Enter Your Email ID';
            }

            if (error && Object.keys(error).length > 0) {
                this.setState({ errorMsg: error });
            } else {
                await this.setState({ errorMsg: {} });
                await this.submitHandler();
            }

        } catch (e) {
            console.log("Error in Validation Function is :-", e);
        }
    }

    submitHandler = () => {
        try {
            const Data = JSON.stringify({
                one: this.state.one,
                two: this.state.two,
                three: this.state.three,
                four: this.state.four,
                five: this.state.five,
                six: this.state.six,
                seven: this.state.seven,
                eight: this.state.eight,
                nine: this.state.nine,
                ten: this.state.ten,
            })

            try {
                let testURL = "http://vsp.easydevices.in:6789/add-survey";
                fetch(testURL, {
                    "method": "POST",
                    "headers": {
                        "content-type": "application/json",
                        "accept": "application/json"
                    },
                    body: Data,
                }).then((response) => response.json()).then((responseData) => {
                    console.log(responseData)
                    if (responseData.status) {
                        alert("Survey is submit successfully.")
                        const { history } = this.props;
                        window.location.replace("/demo#")

                    } else {
                        alert("Oops!, Something Wrong in Submit Button");
                    }
                }).catch(err => {
                    console.log(err);
                });
            } catch (e) {
                console.log("Error in API Section is:-", e);
            }

        } catch (error) {
            console.log('Error in Submit is:-', error);
            alert('Somthing Wrong in Submit Button');
        }
    }

    onChange = (event) => {
        let Name = event.target.name;
        let value = event.target.value;
        this.setState({ [Name]: value });
    }

    render() {
        let { description, user } = this.state
        if (this.state.loading) {
            return (
                <>
                    loading...
                </>
            )
        }
        else {
            return (
                <>
                    <div className="container">
                        <div className="breadcrumb-area py-0 md:mb-10 mb-6 justify-between sm:flex flex-column">
                            <h3 className="md:text-2xl text-lg font-semibold mb-2"> Take Survey </h3>
                            <ul class="breadcrumb flex flex-row text-md">
                                <li className="text-md"><a href="demo#/dashboard">Dashboard</a></li>
                                <li className="text-md active">Take Survey</li>
                            </ul>
                        </div>

                        <div className="grid lg:grid-cols-3 mt-12 gap-8">

                            <div className="bg-white rounded-md lg:shadow-md shadow col-span-3">


                                <div className="grid grid-cols-1 lg:p-6 p-4">
                                    <div className="col-span-2">
                                        <label htmlFor="first-name"> 1. How would you rate your overall experience?</label>
                                        {/* <input type="text" placeholder="" id="first-name" value={user.first_name || ""} style={{ backgroundColor: 'white' }} className="shadow-none with-border" /> */}
                                        <br />
                                        <StarRatings
                                            name="one"
                                            changeRating={this.changeRating}
                                            starRatedColor="green"
                                            rating={this.state.one}
                                            starDimension="40px"
                                            starSpacing="15px"
                                        />
                                    </div>
                                    <br />
                                    <div className="col-span-2">
                                        <label htmlFor="last-name"> 2. Would you be keen on using the platform once live?</label>
                                        {/* <input type="text" placeholder="" id="last-name" value={user.last_name || ""} className="shadow-none with-border" /> */}
                                        <br />
                                        <StarRatings
                                            name="two"
                                            changeRating={this.changeRating}
                                            starRatedColor="green"
                                            rating={this.state.two}
                                            starDimension="40px"
                                            starSpacing="15px"
                                        />
                                    </div>
                                    <br />
                                    <div className="col-span-2">
                                        <label htmlFor="email"> 3. How easy is the platform usage?</label>
                                        {/* <input type="text" placeholder="" id="email" value={user.email || ""} className="shadow-none with-border" /> */}
                                        <br />
                                        <StarRatings
                                            name="three"
                                            changeRating={this.changeRating}
                                            starRatedColor="green"
                                            rating={this.state.three}
                                            starDimension="40px"
                                            starSpacing="15px"
                                        />
                                    </div>
                                    <br />
                                    <div className="col-span-2">
                                        <label htmlFor="first-name"> 4. The platform seems a good tool to find a mid-term pathway for my aspirations</label>
                                        {/* <input type="text" placeholder="" id="first-name" value={user.age} className="shadow-none with-border" /> */}
                                        <br />
                                        <StarRatings
                                            name="four"
                                            changeRating={this.changeRating}
                                            starRatedColor="green"
                                            rating={this.state.four}
                                            starDimension="40px"
                                            starSpacing="15px"
                                        />
                                    </div>
                                    <br />
                                    <div className="col-span-2">
                                        <label htmlFor="last-name"> 5. How likely are you to recommend our website to a friend?</label>
                                        {/* <input type="text" placeholder="" id="last-name" value={user.gender} className="shadow-none with-border" /> */}
                                        <br />
                                        <StarRatings
                                            name="five"
                                            changeRating={this.changeRating}
                                            starRatedColor="green"
                                            rating={this.state.five}
                                            starDimension="40px"
                                            starSpacing="15px"
                                        />
                                    </div>
                                    <br />
                                    <div className="col-span-2">
                                        <label htmlFor="email"> 6. I you would be advised by the platform; how likely would you follow its recommendations?</label>
                                        {/* <input type="text" placeholder="" id="email" value={user.ethnicity} className="shadow-none with-border" /> */}
                                        <br />
                                        <StarRatings
                                            name="six"
                                            changeRating={this.changeRating}
                                            starRatedColor="green"
                                            rating={this.state.six}
                                            starDimension="40px"
                                            starSpacing="15px"
                                        />
                                    </div>
                                    <br />
                                    <br />

                                    <div className="col-span-2">
                                        <label htmlFor="about">7. Was there anything that WOW you?</label>
                                        <textarea id="about" name="seven" rows="3" maxLength="200" onChange={(e) => this.onChange(e)} value={this.state.seven} className="with-border" style={{ padding: 10 }}></textarea>
                                    </div>
                                    <div className="col-span-2">
                                        <label htmlFor="about">8. Is there anything missing you would add?</label>
                                        <textarea id="about" name="eight" rows="3" maxLength="200" onChange={(e) => this.onChange(e)} value={this.state.eight} className="with-border" style={{ padding: 10 }}></textarea>
                                    </div>
                                    <div className="col-span-2">
                                        <label htmlFor="about">9. Would you please give us an overall comment?</label>
                                        <textarea id="about" name="nine" maxLength="200" onChange={(e) => this.onChange(e)} rows="3" value={this.state.nine} className="with-border" style={{ padding: 10 }}></textarea>
                                    </div>
                                </div>


                                <hr className="my-5 mx-6" />

                                <div className="bg-gray-10 p-6 pt-0 flex justify-end space-x-3">
                                    <button className="p-2 px-4 rounded bg-gray-50 text-red-500"> Cancel </button>
                                    <button type="button" className="button bg-blue-700" onClick={() => { this.submitHandler() }}> Save </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    }
}

export default withRouter(TakeSurvey);