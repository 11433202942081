import React, { Component } from 'react';
import { Redirect } from 'react-router';
import Experience from '../Components/Profile/Experience';
import Constants from '../Utilities/Constants';

class MyExperience extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTabActiveID: "cv",
            user: {},
            experience: [],
            editIndex: null,
            loading: true,

            name: "",
            role: "",
            location: "",
            toDate: "",
            fromDate: "",
            description: "",
        }
    }

    componentDidMount() {
        this.getUser();
    }

    getUser = async () => {

        let user = localStorage.getItem('user');
        user = JSON.parse(user);
        if (user) {
            this.setState({ loading: false, user: user, experience: user.experience });
            console.log("found user: ", user)
        }
        else {
            this.setState({ loading: false, user: null, redirect: true })
        }
    }

    onChangeText = async (event) => {
        let { experiences, index } = this.state
        let name = event.target.name;
        let value = event.target.value;
        console.log("neme: ", name, value);
        // experiences[index][name] = value;
        this.setState({ [name]: value })
    }

    addExperience = async () => {
        let { experience, name, role, location, toDate, fromDate, description } = this.state;

        experience.push({
            name, role, location, description, toDate, fromDate
        })

        this.setState({ name: "", role: "", location: "", description: "", toDate: "", fromDate: "", experience })
    }

    removeExperience = async (index) => {
        // console.log("rem: ", index);
        let { experience } = this.state;
        experience = experience.filter((exp, id) => index != id);
        this.setState({ experience });
        // console.log("rem: ", experience);
    }

    onSaveUser = async () => {

        let { user, error, experience } = this.state;
        let userID = user._id
        this.setState({ saving: true });

        user.experience = experience;

        fetch(Constants.url + `users/${userID}`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(user)
            })
            .then((response) => response.json())
            .then((responseData) => {
                console.log(responseData)
                if (responseData.status) {
                    localStorage.setItem('user', JSON.stringify(user));
                    alert("User Details Successfully Updated.")
                    this.setState({ saving: false });

                } else {
                    alert("Something went wrong, Please try again after sometime.");
                    this.setState({ saving: false })
                }
            })
            .catch(err => {
                this.setState({ saving: false })
                alert("Something went wrong, Please try again after sometime.");
                console.log(err);
            });

    }

    render() {
        const { user, experience } = this.state;
        if (this.state.loading) {
            return (
                <>Loading...</>
            )
        }
        else {

            return (
                <div className="container">
                    {
                        this.state.redirect &&
                        <Redirect path="/" />
                    }
                    <div className="breadcrumb-area py-0 md:mb-10 mb-6 justify-between sm:flex flex-column">
                        <h3 className="md:text-2xl text-lg font-semibold mb-2"> My Experience </h3>
                        <ul class="breadcrumb flex flex-row text-md">
                            <li className="text-md"><a href="demo#/dashboard">Dashboard</a></li>
                            <li className="text-md"><a href="demo#/my-profile">User Profile</a></li>
                            <li className="text-md active">My Experience</li>
                        </ul>
                    </div>
                    <div className="flex items-center gap-x-4 mb-5">
                        <img src="../assets/images/avatars/avatar-4.jpg" alt="" className="rounded-full shadow w-12 h-12" />
                        <div>
                            <h4 className="-mb-1 text-base">{user.name}</h4>
                            <span className="text-sm">
                                <span className="icon-material-outline-add-location"></span>
                                Bournemouth
                            </span>
                        </div>
                    </div>

                    <div className="lg:flex lg:space-x-4 mt-4">
                        <div className="lg:w-8/12 space-y-4" style={{ width: '65.5%' }}>
                            <nav className="cd-secondary-nav extanded uppercase nav-small">
                                <ul className="space-x-3 tab-container" uk-scrollspy-nav="closest: li; scroll: true">
                                    <li className={this.state.isTabActiveID == "cv" ? "active" : ""} onClick={() => { this.setState({ isTabActiveID: "cv" }) }}><a className="lg:px-2">CV</a></li>
                                    <li className={this.state.isTabActiveID == "About" ? "active" : ""} onClick={() => { this.setState({ isTabActiveID: "About" }) }}><a className="lg:px-2">About me</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="lg:flex lg:space-x-4 mt-4">
                        <div className="lg:w-8/12 space-y-4">

                            <div id="announcement" className="tube-card p-5">
                                <h6 className="text-xl font-semibold mb-3"> Question </h6>
                                <hr />
                                <div className="p-5 flex flex-column">
                                    <div className="checkbox">
                                        <input type="checkbox" id="chekcbox1" checked="true" />
                                        <label for="chekcbox1"><span className="checkbox-icon"></span> Checkbox</label>
                                    </div>
                                    <div className="checkbox">
                                        <input type="checkbox" id="chekcbox1" checked="" />
                                        <label for="chekcbox1"><span className="checkbox-icon"></span> Checkbox</label>
                                    </div>
                                </div>
                                {/* <div className="flex space-x-2">
                                    <button type="button" className="button">Save</button>
                                    <button type="button" className="button gray">Discard</button>
                                </div> */}
                            </div>

                            {
                                experience && experience.map((expData, index) => {
                                    return (
                                        <div id="announcement" className="tube-card p-5" key={index}>
                                            <div className="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div className="col-md-6">
                                                    <h3 className="text-xl font-semibold mb-3">{expData.name}</h3>
                                                </div>
                                                <div className="col-md-6" style={{ display: 'flex', justifyContent: 'right' }}>
                                                    <div className="text-right">
                                                        {/* <button type="button" className="bg-blue-700 p-2 px-4 rounded text-white" style={{ marginLeft: 2 }}><ion-icon name="pencil" class="md hydrated" role="img" aria-label="key outline" style={{ paddingTop: 4 }}></ion-icon></button> */}
                                                        <button type="button" className="bg-red-500 p-2 px-4 rounded text-white" style={{ marginLeft: 2 }} onClick={() => { this.removeExperience(index) }}>
                                                            <ion-icon name="trash-outline" class="md hydrated" role="img" aria-label="key outline" style={{ paddingTop: 4 }}></ion-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                expData?.role &&
                                                <h4 className="leading-8 text-xl">Role: {expData?.role}</h4>
                                            }
                                            {
                                                expData?.location &&
                                                <p>Location: {expData?.location}</p>
                                            }
                                            {
                                                expData?.description &&
                                                <p>Description: {expData?.description}</p>
                                            }
                                        </div>
                                    )
                                })
                            }
                            <div className="bg-white rounded-md lg:shadow-md shadow col-span-3">

                                {/* <Experience /> */}
                                <div className="grid grid-cols-2 gap-3 lg:p-6 p-4">
                                    <div className="text-black font-semibold text-base pt-2">Add Experience</div>
                                    <div className="text-right">
                                        {/* <button type="button" className="bg-blue-700 p-2 px-4 rounded text-white" onClick={() => { this.onAddForms(); }}>+</button> */}
                                        {/* <button type="button" className="bg-blue-700 p-2 px-4 rounded text-white ml-2" onClick={() => { this.onAddForms(); }}><ion-icon name="add" class="md hydrated" role="img" aria-label="key outline" style={{ paddingTop: 4 }}></ion-icon></button> */}
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 gap-3 lg:px-6 pb-6">
                                    <div className="col-span-2">
                                        <label for="title">Title</label>
                                        <input name={'name'} type='text' placeholder={''} id="name" className="shadow-none with-border" value={this.state.name} onChange={(event) => { this.onChangeText(event) }} />
                                    </div>
                                    <div className="col-span-2">
                                        <label for="role">Role</label>
                                        <input name={'role'} type='text' placeholder={''} id="role" className="shadow-none with-border" value={this.state.role} onChange={(event) => { this.onChangeText(event) }} />
                                    </div>
                                    <div className="col-span-2">
                                        <label for="location">Location</label>
                                        <input name={'location'} type='text' placeholder={''} id="location" className="shadow-none with-border" value={this.state.location} onChange={(event) => { this.onChangeText(event) }} />
                                    </div>
                                    <div className="">
                                        <label for="fromDate">Form Date</label>
                                        <input name={'fromDate'} type={'text'} placeholder={''} id="fromDate" className="shadow-none with-border" value={this.state.fromDate} onChange={(event) => { this.onChangeText(event) }} />
                                    </div>
                                    <div className="">
                                        <label for="toDate">To Date</label>
                                        <input name={'toDate'} type='text' placeholder={''} id="toDate" className="shadow-none with-border" value={this.state.toDate} onChange={(event) => { this.onChangeText(event) }} />
                                    </div>
                                    <div className="col-span-2">
                                        <label for="description">Description</label>
                                        <textarea name={'description'} cols="3" placeholder={''} id="description" className="shadow-none with-border" value={this.state.description} onChange={(event) => { this.onChangeText(event) }} />
                                    </div>
                                    <div className="col-span-2 text-center">
                                        <button type="button" className="bg-blue-700 p-2 px-4 rounded text-white ml-2 center" onClick={() => { this.addExperience(); }}>Add Experience</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-2 text-center">
                                <button type="button" disabled={this.state.saving} className="bg-blue-700 p-2 px-4 rounded text-white ml-2 center" onClick={() => { this.onSaveUser(); }}>Save</button>
                            </div>
                        </div>

                        <div className="lg:w-4/12 space-y-4 lg:mt-0 mt-4">

                            <div uk-sticky="top:600;offset:; offset:90 ; media: 1024" className="uk-sticky" >
                                <div className="tube-card p-5 uk-sticky" uk-sticky="top:600;offset:; offset:90 ; media: @s" >

                                    <div className="-m-5 divide-y divide-gray-200 text-sm">
                                        <div className="flex items-center px-5 py-3" style={{ alignItems: 'normal' }}>  <ion-icon name="trash-outline" className="text-2xl mr-2 md hydrated" style={{ marginTop: 2 }} role="img" aria-label="key outline"></ion-icon> <span style={{ marginLeft: 3 }}> Delete CV </span></div>
                                        <div className="flex items-center px-5 py-3" style={{ alignItems: 'normal' }}>  <ion-icon name="download-outline" className="text-2xl mr-2 md hydrated" role="img" aria-label="download outline"></ion-icon> <span style={{ marginLeft: 3 }}>  Download CV</span> </div>
                                    </div>

                                </div><div className="uk-sticky-placeholder" style={{ height: '346px', margin: '0px' }} hidden=""></div>
                            </div>
                            <div className="uk-sticky-placeholder" style={{ height: '346px', margin: '0px' }} hidden=""></div>

                        </div>
                    </div>
                    {/* </div> */}
                </div>
            );
        }
    }
}

export default MyExperience;