import React, { Component } from 'react';


class Footer extends Component {

    render() {

        return (

            // < !--Footer -- >
            <div className="lg:mb-0 py-5 uk-link-reset" style={{ backgroundColor: '#361A54' }}>
                <div className="flex flex-col items-center justify-between lg:flex-row max-w-6xl mx-auto lg:space-y-0 space-y-3">
                <div class="flex items-center space-x-2 justify-center">
                    <a href="#">
                        <ion-icon name="logo-facebook" class="p-2 text-2xl md hydrated text-white"></ion-icon>
                    </a>
                    <a href="#">
                        <ion-icon name="logo-linkedin" class="p-2 text-2xl md hydrated text-white"></ion-icon>
                    </a>
                    <a href="#">
                        <ion-icon name="logo-twitter" class="p-2 text-2xl md hydrated text-white"></ion-icon>
                    </a>
                    <a href="#">
                        <ion-icon name="logo-instagram" class="p-2 text-2xl md hydrated text-white"></ion-icon>
                    </a>
                    <a href="#">
                        <ion-icon name="logo-youtube" class="p-2 text-2xl md hydrated text-white"></ion-icon>
                    </a>
                </div>
                    <div className="flex space-x-2 text-white uppercase">
                        <a href="#"> Accessibility</a>
                        <a href="#"> Cookies</a>
                        <a href="#"> Disclaimer</a>
                        <a href="#"> Jobs</a>
                        <a href="#"> Privacy</a>
                        <a href="#"> Sitemap</a>
                    </div>
                    {/* <p className="capitalize"> © copyright 2021 by Virtual Skills Platform</p> */}
                    <div className="flex space-x-2 text-white uppercase" style={{position:'relative',top:-70}}>
                        <img src="/assets/images/Footer.png" className="logo_inverse" alt=""  style={{height:110,width:100}}/>
                    </div>
                </div>
            </div>

        );
    }
}

export default Footer;